<template>
  <SearchBox searchName="Creations" storeSearchMethod="searchCreations" :lastSearch="LastCreationSearch"
             :searchOptions="['E-mail', 'Title', 'Name', 'Creation ID']" :showRecentSearch="false"
             svgD="M8 14v3m4-3v3m4-3v3M3 21h18M3 10h18M3 7l9-4 9 4M4 10h16v11H4V10z"
  >

    <Table :headers="['Creation', ' ', 'Canvas Count', 'Version', 'Owner', 'Last Change', 'Orderable', ' ']">

      <CreationTableRow v-for="tableRow in CreationTableRows" :key="tableRow.id" :tableRow="tableRow" />

    </Table>

  </SearchBox>
</template>

<script>
import SearchBox from '@/components/SearchBox.vue';
import Table from '@/components/Table.vue';
import CreationTableRow from './CreationsTableRow.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'CreationSearch',
  components: { SearchBox, Table, CreationTableRow },
  computed: mapGetters(['CreationTableRows', 'LastCreationSearch']),
};
</script>