import { render, staticRenderFns } from "./PageHistorySideBar.vue?vue&type=template&id=45ea06fa&scoped=true&"
import script from "./PageHistorySideBar.vue?vue&type=script&lang=js&"
export * from "./PageHistorySideBar.vue?vue&type=script&lang=js&"
import style0 from "./PageHistorySideBar.vue?vue&type=style&index=0&id=45ea06fa&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45ea06fa",
  null
  
)

export default component.exports