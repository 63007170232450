<template>
  <tr>
    <td class="px-6 py-4">
      <div class="text-sm text-gray-900 capitalize" :style="titleStyle">
        {{ title }}
      </div>
    </td>

    <td class="px-6 py-4"></td>

    <td class="px-6 py-4">
      <div class="text-sm text-gray-900">
        {{ amount }}
      </div>
    </td>
    <td class="px-6 py-4">
      <div class="text-sm text-gray-900" :style="priceStyle">
        {{ price }}
      </div>
    </td>
  </tr>
</template>

<script>
export default {
  name: 'OrderPriceInfoTableRow',
  props: {
    title: String,
    amount: Number,
    price: String,
    titleStyle: String,
    priceStyle: String,
  },
};
</script>