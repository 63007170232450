import httpClient from '../../../httpClient';

// Get Editor content
const get_Templates = () =>
  httpClient.get('editor/templates');
const get_Backgrounds = () =>
  httpClient.get('editor/backgrounds');
const get_ClipArt = () =>
  httpClient.get('editor/clipart');
const get_Shapes = () =>
  httpClient.get('editor/shapes');

// Change the title of an image- or shapedirectory
const rename_Directory = (id, title, directoryType) =>
  httpClient.put(`editor/${directoryType}/update/${id}`, { title });

// Add a new image- or shapedirectory
const add_Directory = (title, type, creationType, directoryType) =>
  httpClient.post(`editor/${directoryType}/add`, { type, title, creationType });

// Change the activation state of an image- or shapedirectory
const updateActive_Directory = (id, active, directoryType) =>
  httpClient.put(`editor/${directoryType}/update/${id}`, { active });

// Move Images or Shapes to new directory
const move_Content = (id, ids, directoryType) =>
  httpClient.put(`editor/${directoryType}/move/${id}`, { ids });

// Move Images or Shapes to new directory
const changeactivation_Content = (ids, active, directoryType) =>
  httpClient.put(`editor/${directoryType}/changeactivation/${active}`, { ids });

// Save the sortOrder
const save_sortOrder_Content = (ids, directoryType) =>
  httpClient.put(`editor/${directoryType}/savesorting`, { ids });
const save_sortOrder_Template = (ids) =>
  httpClient.put('editor/templates/savesorting', { ids });

// Upload editor Content
const upload_Content = (file, directoryType) =>
  httpClient.post(`editor/${directoryType}/uploadimage`, file, { headers: { 'Content-Type': 'multipart/form-data' } });

export {
  get_Templates,
  get_Backgrounds,
  get_ClipArt,
  get_Shapes,

  rename_Directory,
  add_Directory,
  updateActive_Directory,
  move_Content,
  changeactivation_Content,
  save_sortOrder_Content,
  save_sortOrder_Template,
  upload_Content,
};