<template>
  <div class="flex justify-center mt-24">
    <div class="w-full max-w-4xl">
      <div class="bg-white shadow-md rounded py-3 mb-1 pl-3 flex">

        <router-link class="mr-1 px-2 pt-1 rounded
                focus:outline-none focus:shadow-outline flex hover:bg-gray-200"
                     to="/orders"
        >
          <SVGIcon d="M10 19l-7-7m0 0l7-7m-7 7h18" />
        </router-link>

        <SVGIcon iconStyle="mt-1 mr-2" d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z" />
        <h1 class="text-gray-700 font-bold text-2xl">Order: {{ OrderID }}</h1>
      </div>


      <SVGIcon v-if="!dataLoaded"
               iconStyle="animate-reverse-spin w-full mt-5 h-10"
               d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
      />

      <OrderInfoTable v-if="dataLoaded" />
      <OrderPriceInfoTable v-if="dataLoaded" />

    </div>
  </div>
</template>

<script>
import OrderInfoTable from './OrderInfoTable';
import OrderPriceInfoTable from './OrderPriceInfoTable';
import SVGIcon from '@/components/SVGIcon.vue';

export default {
  name: 'OrderView',
  components: {
    OrderInfoTable,
    OrderPriceInfoTable,
    SVGIcon,
  },
  data () {
    return {
      OrderID: '',
      dataLoaded: false,
    };
  },
  methods: {
    async getOrder(order_id) {
      this.dataLoaded = false;
      await this.$store.dispatch('getOrder', order_id);
      this.dataLoaded = true;
    },
  },
  created() {
    //set Order ID
    this.OrderID = this.$route.params.order_id;
    this.getOrder(this.OrderID);
  },
};
</script>