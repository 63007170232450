<template>
  <div class="flex flex-col mb-16">
    <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="py-2 inline-block w-full sm:px-6 lg:px-8">
        <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">

          <table class="w-full divide-y divide-gray-200">

            <thead class="bg-gray-50 font-medium text-gray-500 text-xs tracking-wider uppercase">
              <tr>
                <th v-for="(header, index) in headers" :key="index"
                    scope="col" class="px-6 py-3 text-left whitespace-nowrap"
                >
                  {{ header }}
                </th>
              </tr>
            </thead>
            <tbody class="bg-white divide-y divide-gray-200">

              <slot></slot>

            </tbody>

          </table>

        </div>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  name: 'Table',
  props: { headers: Array },
};
</script>