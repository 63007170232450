import { search_Email, search_Name, search_Title, search_CreationID }
  from './creationSearchEndpoints';

import { ReadableDateTimeFormat } from '@/Helpers/helpers';

const state = {
  creationTableRows: [],
  lastCreationSearch: { searchOption: '', searchText: '', searchLimit: 50 },
};

const getters = {
  CreationTableRows: (state) => { return state.creationTableRows; },
  LastCreationSearch: (state) => { return state.lastCreationSearch; },
};
const actions = {
  async searchCreations({ commit }, { searchOption, searchText, searchLimit }) {
    // Variables
    var response = null;

    // Execute API request based on the chosen searchOption
    switch (searchOption) {
      case 'E-mail':
        response = await search_Email(searchText, searchLimit);
        break;
      case 'Name':
        response = await search_Name(searchText, searchLimit);
        break;
      case 'Title':
        response = await search_Title(searchText, searchLimit);
        break;
      case 'creation ID':
        response = await search_CreationID(searchText);
        break;
      default:
        commit('RESET_CREATIONTABLE');
        return false;
    }

    // Check if there are results
    if (response.data.message !== 'Found') {
      commit('RESET_CREATIONTABLE');
      return false;
    }

    commit('POPULATE_CREATIONTABLE', response.data.magazines);
    commit('SET_LASTCREATIONSEARCH', { searchOption: searchOption, searchText: searchText, searchLimit: searchLimit });
    return true;
  },
};

const mutations = {
  RESET_CREATIONTABLE(state) {
    state.creationTableRows = [];
  },
  POPULATE_CREATIONTABLE(state, creations) {
    state.creationTableRows = [];

    creations.forEach((element) => {
      state.creationTableRows.push({
        id: element.id,
        fullName: element.firstName + element.lastName !== '' ?
          element.firstName + ' ' + element.lastName : element.nickName,
        email: element.email,
        title: element.title,
        canvasCount: element.pageCount !== null ? String(element.pageCount) : '-',
        canvasVersion: element.canvasVersion,
        lastContentChange: ReadableDateTimeFormat(element.lastContentChange, '\n   '),
        status: element.status,
        owner: element.owner || '-',
        thumbnailLink: element.coverThumbnailLink,
      });
    });
  },
  SET_LASTCREATIONSEARCH(state, lastSearch) {
    state.lastCreationSearch = lastSearch;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
