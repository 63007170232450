<template>
  <!-- BaseScreen -->
  <div class="flex mt-16 justify-start">

    <!-- SideBar -->
    <div class="bg-gray-100 text-gray-500 text-s pl-16">
      <!-- Title -->
      <h1 class="text-gray-400 text-s uppercase font-bold py-2 mt-16">Editor</h1>

      <!-- Button for loop for chosing editor option -->
      <div v-for="option in editorOptions" :key="option">

        <button @click="ChangeEditorOption(option)"
                class="px-5 py-2 rounded-full rounded-r-none w-full text-left flex"
                :class="option === activeEditorOption ? 'bg-white hover:bg-gray-50' : 'hover:bg-gray-200'"
        >
          <SVGIcon iconStyle="mr-2" :d="GetEditorOptionIcon(option)" />
          {{ option }}
        </button>

      </div>
    </div>

    <!-- Content -->
    <div class="w-full mt-8 mx-10 h-screen max-w-screen-2xl">

      <!-- Creation Type tabs tabs -->
      <ul v-show="activeEditorOption === 'Clip-Art' || activeEditorOption === 'Backgrounds' "
          class="flex flex-wrap text-sm font-medium text-center text-gray-500"
      >
        <li class="mr-1" v-for="type in creationTypes" :key="type">
          <button @click="ChangeCreationType(type)" :disabled="type === activeCreationType"
                  class="inline-block px-3 py-1 rounded-t-lg border-t border-r border-l border-gray-100 -mb-0.25 capitalize"
                  :class="type === activeCreationType ? 'bg-white' : 'bg-gray-100' "
          >
            {{ type }}
          </button>
        </li>
      </ul>


      <div class="bg-white rounded shadow-md text-gray-500">

        <!-- Header -->
        <div class="flex justify-between border-gray-100 border p-5 w-full">
          <!-- Folder -->
          <div class="flex flex-1">
            <!-- Title -->
            <h1 class="flex py-1 px-2 font-bold">
              <SVGIcon d="M5 19a2 2 0 01-2-2V7a2 2 0 012-2h4l2 2h4a2 2 0 012 2v1M5 19h14a2 2 0 002-2v-5a2 2 0 00-2-2H9a2 2 0 00-2 2v5a2 2 0 01-2 2z" />
              <span class="pl-2">Folder: </span>
            </h1>

            <!-- Folder Selection -->
            <select
              name="folders" id="folders"
              class="border border-gray-300 bg-white cursor-pointer max-h-9 rounded-md py-1 px-2 mx-0.5"
              v-model="selectedNewActiveFolder"
            >
              <option v-for="folder in CurrentFolders" :key="folder"
                      :value="folder"
              >
                {{ folder }}
              </option>
            </select>

            <!-- Rename Folder Button -->
            <TooltipButton
              v-if="activeEditorOption !== 'Templates'"
              tooltip="Rename" tooltipOffset="-ml-4" :onClick="EnableRenameFolderModal"
              svgD="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
            />

            <!-- Add Folder Button -->
            <TooltipButton
              v-if="activeEditorOption !== 'Templates'"
              tooltip="Add" tooltipOffset="-ml-1" :onClick="EnableAddFolderModal"
              svgD="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
            />

            <!-- Update Folder activation Button -->
            <TooltipButton
              v-if="activeEditorOption !== 'Templates'"
              :tooltip="ActiveFolder.active === 1 ? 'Deactivate' : 'Activate'" :tooltipOffset="ActiveFolder.active === 1 ? '-ml-6' : '-ml-4'" :onClick="EnableFolderActivationModal"
              svgD="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9"
            />

            <!-- Login to template maker Account Button -->
            <TooltipButton
              v-if="activeEditorOption === 'Templates'"
              tooltip="Login" tooltipOffset="-ml-2" :onClick="LoginAsMaker"
              svgD="M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1"
            />

            <!-- Active Tag to show if this folder is active in the editor -->
            <div
              v-if="activeEditorOption !== 'Templates'"
              :class="ActiveFolder.active === 1 ? 'green-tag' : 'orange-tag'"
              class="rounded-full text-center font-semibold uppercase text-sm w-min pt-1.5 px-2 mx-2"
            >
              {{ ActiveFolder.active === 1 ? "Active" : "Inactive" }}
            </div>

          </div>

          <!-- Buttons for current Selections -->
          <div class="flex flex-2">
            <!-- Selection Amount -->
            <h1 v-if="activeEditorOption !== 'Templates'" class="py-1 px-2 font-bold whitespace-nowrap">
              <span class="font-light px-2">{{ CurrentSelectedItems.length }}</span> Selected:
            </h1>

            <!-- Clear Selection Button -->
            <TooltipButton
              v-if="activeEditorOption !== 'Templates'"
              tooltip="Clear Selection" tooltipOffset="-ml-10"
              :disabled="CurrentSelectedItems.length === 0" :onClick="ClearSelection"
              svgD="M6 18L18 6M6 6l12 12"
            />

            <!-- Move Selection Button -->
            <TooltipButton
              v-if="activeEditorOption !== 'Templates'"
              tooltip="Move" tooltipOffset="-ml-2"
              :disabled="CurrentSelectedItems.length === 0" :onClick="EnableMoveFolderModal"
              svgD="M3 10h10a8 8 0 018 8v2M3 10l6 6m-6-6l6-6"
            />

            <!-- Delete Selection Button -->
            <TooltipButton
              v-if="activeEditorOption !== 'Templates'"
              tooltip="Delete" tooltipOffset="-ml-3"
              :disabled="CurrentSelectedItems.length === 0" :onClick="EnableDeactivateContentModal"
              svgD="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
            />

            <!-- Save Sorting Button -->
            <TooltipButton
              tooltip="Save Sorting" tooltipOffset="-ml-8" :onClick="SaveSorting"
              svgD="M8 7H5a2 2 0 00-2 2v9a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-3m-1 4l-3 3m0 0l-3-3m3 3V4"
            />

            <!-- Upload Button -->
            <TooltipButton
              v-if="activeEditorOption !== 'Templates' && activeEditorOption !== 'Shapes'"
              tooltip="Upload" tooltipOffset="-ml-3" :onClick="EnableUploadImageModal"
              svgD="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12"
            />

          </div>
        </div>

        <!-- Loading spinner -->
        <SVGIcon
          v-if="!dataLoaded"
          iconStyle="animate-reverse-spin w-full my-5 h-10"
          d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
        />

        <!-- Current Items -->
        <Draggable
          v-if="dataLoaded" class="flex flex-wrap p-5"
          v-model="CurrentItemsProperty" ghost-class="ghost" @end="onEnd"
        >

          <!-- Drag transition -->
          <transition-group type="transition" name="flip-list">

            <!-- Button per item -->
            <button
              v-for="item in CurrentItemsProperty"
              :key="item.id" :id="item.id"
              class="border rounded-md p-3 mx-0.5 my-0.5 cursor-move"
              :class="item.active === 0 ? 'border-red-500' : CurrentSelectedItems.includes(item) ? 'border-blue-500' : 'border-gray-300 border-2'"
              @click="OnItemButtonClick(item)"
            >

              <img
                class="h-28"
                :class="['Templates', 'Backgrounds'].includes(activeEditorOption) ? 'w-20' : 'w-28'"
                :src="item.thumbnailLink" @error="ReplaceDefault"
              >

            </button>

          </transition-group>

        </Draggable>

      </div>
    </div>

    <RenameFolderModal v-if="RenameFolderModalEnabled" />
    <AddFolderModal v-if="AddFolderModalEnabled" />
    <DeactivateFolderModal v-if="DeactivateFolderModalEnabled" />
    <ActivateFolderModal v-if="ActivateFolderModalEnabled" />
    <MoveModal v-if="MoveModalEnabled" />
    <DeactivateContentModal v-if="DeactivateContentModalEnabled" />
    <UploadImageModal v-if="UploadImageModalEnabled" />

  </div>
</template>

<script>
import SVGIcon from '@/components/SVGIcon.vue';
import TooltipButton from './TooltipButton.vue';
import RenameFolderModal from './modals/RenameFolderModal.vue';
import AddFolderModal from './modals/AddFolderModal.vue';
import DeactivateFolderModal from './modals/DeactivateFolderModal.vue';
import ActivateFolderModal from './modals/ActivateFolderModal.vue';
import MoveModal from './modals/MoveModal.vue';
import DeactivateContentModal from './modals/DeactivateContentModal.vue';
import UploadImageModal from './modals/UploadImageModal.vue';
import { mapGetters } from 'vuex';
import draggable from 'vuedraggable';

export default {
  name: 'OrderView',
  components: {
    SVGIcon,
    TooltipButton,
    RenameFolderModal,
    AddFolderModal,
    DeactivateFolderModal,
    ActivateFolderModal,
    MoveModal,
    DeactivateContentModal,
    UploadImageModal,
    Draggable: draggable,
  },
  data () {
    return {
      activeEditorOption: 'Templates',
      editorOptions: [
        'Templates',
        'Backgrounds',
        'Clip-Art',
        'Shapes',
      ],
      activeCreationType: 'default',
      creationTypes: [
        'default',
        'linda',
      ],
      selectedNewActiveFolder: '',
      dataLoaded: true,

      // Indexes for dragging
      oldIndex: '',
      newIndex: '',
    };
  },
  watch: {
    selectedNewActiveFolder: function (value) {
      this.$store.commit('RESET_SELECTION');
      this.ChangeActiveFolder(value);
    },
    ActiveFolder: function (value) {
      this.selectedNewActiveFolder = value.title;
    },
  },
  computed: {
    ...mapGetters(['ActiveEditorOption', 'CurrentFolders', 'CurrentContent', 'CurrentItems', 'CurrentSelectedItems',
      'ActiveFolder', 'RenameFolderModalEnabled', 'AddFolderModalEnabled', 'DeactivateContentModalEnabled', 'ActiveCreationType',
      'DeactivateFolderModalEnabled', 'ActivateFolderModalEnabled', 'MoveModalEnabled', 'UploadImageModalEnabled']),

    CurrentItemsProperty: {
      get () { return this.CurrentItems; },
      set (value) { this.$store.commit('SET_CURRENTITEMS', value); },
    },

  },
  methods: {
    // Get Correct SVG D value per editorOption
    GetEditorOptionIcon(option) {
      switch (option) {
        case 'Templates':
          return 'M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z';
        case 'Backgrounds':
          return 'M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z';
        case 'Clip-Art':
          return 'M11 4a2 2 0 114 0v1a1 1 0 001 1h3a1 1 0 011 1v3a1 1 0 01-1 1h-1a2 2 0 100 4h1a1 1 0 011 1v3a1 1 0 01-1 1h-3a1 1 0 01-1-1v-1a2 2 0 10-4 0v1a1 1 0 01-1 1H7a1 1 0 01-1-1v-3a1 1 0 00-1-1H4a2 2 0 110-4h1a1 1 0 001-1V7a1 1 0 011-1h3a1 1 0 001-1V4z';
        case 'Shapes':
          return 'M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z';
        default:
          return '';
      }
    },


    async ChangeEditorOption(newOption) {
      this.activeEditorOption = newOption;
      this.dataLoaded = false;
      await this.$store.dispatch('ChangeEditorOption', newOption);
      this.dataLoaded = true;

      this.selectedNewActiveFolder = this.ActiveFolder.title;
    },

    ChangeCreationType(newType) {
      this.activeCreationType = newType;
      this.$store.dispatch('ChangeCreationType', newType);
    },

    ChangeActiveFolder(newFolder) { this.$store.commit('SET_ACTIVEFOLDER', newFolder); },

    OnItemButtonClick(item) {
      if (this.activeEditorOption === 'Templates') return;

      if (this.CurrentSelectedItems.includes(item)) {
        this.RemoveArrayItemByValue(this.CurrentSelectedItems, item);
      }
      else this.CurrentSelectedItems.push(item);
    },

    RemoveArrayItemByValue(arr, value) {
      for (var i = 0; i < arr.length; i++) {
        if (arr[i] === value) {
          arr.splice(i, 1);
        }
      }
    },

    // On end dragging
    onEnd: function(evt) {
      this.oldIndex = evt.oldIndex;
      this.newIndex = evt.newIndex;
    },

    EnableRenameFolderModal() { this.$store.commit('SET_RENAMEFOLDERMODAL_ENABLED', true); },
    EnableAddFolderModal() { this.$store.commit('SET_ADDFOLDERMODAL_ENABLED', true); },
    EnableMoveFolderModal() { this.$store.commit('SET_MOVEMODAL_ENABLED', true); },
    EnableDeactivateContentModal() { this.$store.commit('SET_DEACTIVATECONTENTMODAL_ENABLED', true); },
    EnableUploadImageModal() { this.$store.commit('SET_UPLOADIMAGEMODAL_ENABLED', true); },
    EnableFolderActivationModal() {
      if (this.ActiveFolder.active === 1) this.$store.commit('SET_DEACTIVATEFOLDERMODAL_ENABLED', true);
      else this.$store.commit('SET_ACTIVATEFOLDERMODAL_ENABLED', true);
    },


    async LoginAsMaker () { await this.$store.dispatch('loginAsMaker', this.ActiveFolder.id); },

    SaveSorting() { this.$store.dispatch('SaveItemSorting'); },

    ClearSelection() { this.$store.commit('RESET_SELECTION'); },
    // Replace thumbnail with default image on error
    ReplaceDefault(e) { e.target.src = process.env.VUE_APP_DEFAULT_IMAGE; },
  },
  async beforeMount () {
    await this.ChangeEditorOption(this.editorOptions.includes(this.ActiveEditorOption) ? this.ActiveEditorOption : 'Templates');
    if (this.creationTypes.includes(this.ActiveCreationType)) this.activeCreationType = this.ActiveCreationType;
  },
};
</script>

<style scoped>

.-mb-0\.25{
    margin-bottom: -1px;
}

.max-w-28 {
    max-width: 7rem;
}

/* Drag move animation */
/* .flip-list-move {
    transition: transform 0.5s;
} */

/* New location for dragged item */
.ghost {
  box-sizing: border-box;
  border: blue 2px dashed;
}

.ghost img {
    opacity: 0;
}
</style>