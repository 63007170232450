import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store';
import Home from '../domains/Home';
import Login from '../domains/auth/Login';
import MakerSearch from '../domains/makers/MakerSearch';
import CreationSearch from '../domains/creations/CreationSearch/CreationSearch';
import CreationView from '../domains/creations/CreationView/CreationView';
import OrderSearch from '../domains/orders/OrderSearch/OrderSearch';
import OrderView from '../domains/orders/OrderView/OrderView';
import Editor from '../domains/editor/EditorView';


Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { requiresAuth: true },
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: { guest: true },
  },
  {
    path: '/makers',
    name: 'Makers',
    component: MakerSearch,
    meta: { requiresAuth: true },
  },
  {
    path: '/creations',
    name: 'creations',
    component: CreationSearch,
    meta: { requiresAuth: true },
  },
  {
    path: '/creations/:creation_id',
    name: 'creationView',
    component: CreationView,
    meta: { requiresAuth: true },
  },
  {
    path: '/orders',
    name: 'Orders',
    component: OrderSearch,
    meta: { requiresAuth: true },
  },
  {
    path: '/orders/:order_id',
    name: 'OrderView',
    component: OrderView,
    meta: { requiresAuth: true },
  },
  {
    path: '/editor',
    name: 'Editor',
    component: Editor,
    meta: { requiresAuth: true },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.isAuthenticated) {
      next();
    }
    else {
      store.dispatch('logout');
      next('/login');
    }

  }
  else {
    next();
  }
});


export default router;
