<template>
  <div class="flex justify-center mt-24">
    <div class="w-full max-w-6xl">
      <div class="bg-white shadow-md rounded px-4 py-3 mb-1 pl-3 flex">

        <!-- Arrow to return back to creation search -->
        <router-link class="mr-1 px-2 pt-1 rounded
                focus:outline-none focus:shadow-outline flex hover:bg-gray-200"
                     to="/creations"
        >
          <SVGIcon d="M10 19l-7-7m0 0l7-7m-7 7h18" />
        </router-link>

        <!-- Header -->
        <SVGIcon iconStyle="mt-1 mr-2" d="M19 20H5a2 2 0 01-2-2V6a2 2 0 012-2h10a2 2 0 012 2v1m2 13a2 2 0 01-2-2V7m2 13a2 2 0 002-2V9a2 2 0 00-2-2h-2m-4-3H9M7 16h6M7 8h6v4H7V8z" />
        <div class="flex text-gray-700 font-bold text-2xl">
          <h1>Creation:&nbsp;</h1>
          <h1 v-if="dataLoaded">{{ CreationInfo.title }}</h1>
          <h1 v-else>-</h1>
        </div>
      </div>

      <!-- Spinner for if the data is still loading -->
      <SVGIcon v-if="!dataLoaded"
               iconStyle="animate-reverse-spin w-full mt-5 h-10"
               d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
      />

      <!-- Displaying general info -->
      <CreationInfoTable v-if="dataLoaded" />

      <!-- List of all pages -->
      <Table v-if="dataLoaded"
             :headers="['Page', ' ', 'Status', 'Highres PNG', 'Maker', 'Last Change', 'History']"
      >

        <CreationPagesTableRow v-for="page in CreationInfo.magazinepages" :key="page.id"
                               :pageInfo="page"
        />

      </Table>

      <!-- Page History sidebar (off by default) -->
      <PageHistorySideBar v-if="ShowPageHistory" />

    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SVGIcon from '@/components/SVGIcon.vue';
import CreationInfoTable from './CreationInfoTable.vue';
import Table from '@/components/Table.vue';
import CreationPagesTableRow from './CreationPagesTableRow.vue';
import PageHistorySideBar from './PageHistorySideBar.vue';

export default {
  name: 'CreationView',
  components: {
    SVGIcon,
    CreationInfoTable,
    Table,
    CreationPagesTableRow,
    PageHistorySideBar,
  },
  data () {
    return {
      creationID: '',
      dataLoaded: false,
    };
  },
  computed: mapGetters(['CreationInfo', 'ShowPageHistory', 'PageHistoryInfo']),
  methods: {
    async getCreation(Creation_id) {
      this.dataLoaded = false;
      await this.$store.dispatch('getCreation', Creation_id);
      this.dataLoaded = true;
    },
  },
  created() {
    //set Creation ID
    this.creationID = this.$route.params.creation_id;
    this.getCreation(this.creationID);
  },
};
</script>