import router from '@/router';
import { change_reprintStatus, change_paymentmethod, change_orderstatus, search_ID_All } from './orderViewEndpoints';
import { CentsToEuros, ReadableDateTimeFormat } from '@/Helpers/helpers';

const state = {
  currentOrder: {},
  currentOrderInfo: {},
  currentOrderInfoReprint: {},
  currentOrderPriceInfo: {},
  currentOrderShippingMethod: '',
};

const getters = {
  OrderInfo: state => state.currentOrderInfo,
  OrderPriceInfo: state => state.currentOrderPriceInfo,
  OrderInfoReprint: state => state.currentOrderInfoReprint,
  OrderShippingMehod: state => state.currentOrderShippingMethod,
};

const actions = {
  async getOrder({ commit }, order_id) {
    const response = await search_ID_All(parseInt(order_id));
    //Check if order number exists
    if (response.data.message === 'No Results') {
      await router.push('/orders');
    }
    //set order objcet
    else {
      let orderObject = response.data.order[0];
      commit('SET_ORDER', orderObject);
    }
  },

  async ChangeReprintProperties({ commit, getters }, newReprintStatus) {
    // setting the correct data types for the database
    if (!newReprintStatus.isReprint) {
      newReprintStatus.reprintorder_id = null;
      newReprintStatus.reprintcause_id = null;
      newReprintStatus.reprintRemarks = null;
    }
    else {
      newReprintStatus.reprintorder_id = parseInt(newReprintStatus.reprintorder_id);
      newReprintStatus.reprintcause_id = parseInt(newReprintStatus.reprintcause_id);
    }

    // Doing the http request
    let response = await change_reprintStatus(
      getters.OrderInfo.id,
      newReprintStatus.isReprint ? 1 : 0,
      newReprintStatus.reprintorder_id,
      newReprintStatus.reprintcause_id,
      newReprintStatus.reprintRemarks,
    );

    // Checking if the request was succesfull
    if (response.data.message === 'Updated') {
      let orderObject = response.data.order[0];
      commit('SET_ORDER', orderObject);
      return true;
    }
    else {
      return false;
    }
  },

  async ChangePaymentMethod({ commit, getters }, newpaymentmethod_id) {
    let response = await change_paymentmethod(
      getters.OrderInfo.id,
      newpaymentmethod_id,
    );

    if (response.data.message !== 'No Results') {
      let orderObject = response.data.order[0];
      commit('SET_ORDER', orderObject);
    }
  },

  async ChangeOrderStatus({ state, commit, getters }, neworderstatus_id) {
    neworderstatus_id = parseInt(neworderstatus_id);

    let response = await change_orderstatus(
      getters.OrderInfo.id,
      neworderstatus_id,
      neworderstatus_id === 2 ? null : state.currentOrderInfo.job_id,
    );

    if (response.data.message !== 'No Results') {
      let orderObject = response.data.order[0];
      commit('SET_ORDER', orderObject);
    }
  },
};

const mutations = {
  SET_ORDER(state, order) {
    state.currentOrder = order;
    state.currentOrderInfo = {
      id: order.id,
      job_id: order.job_id,
      fullName: order.firstName + ' ' + order.lastName,
      email: order.user.email,
      creation: order.magazine.title,
      proof: order.proof,
      printingType: order.printingtype === null ? '-' : order.printingtype.name,
      date: ReadableDateTimeFormat(order.created_at),
      orderstatus_id: order.orderstatus_id,
      status: order.orderstatus.title === 'transferred' ? 'PRINTING' : order.orderstatus.title.toUpperCase(),
      paymentmethod_id: order.paymentmethod_id,
      paymentMethod: order.paymentmethod.name,
      phoneNumber: order.phoneNumber || '-',
      mobileNumber: order.mobileNumber || '-',
      fileUrl: order.fileUrl,
      addres: `${order.street} ${order.houseNumber}${order.houseNumberSuffix || ''}, 
            ${order.city} ${order.country.name_en}`,
      postalCode: order.postalCode,
    };
    state.currentOrderInfoReprint = {
      isReprint: order.isReprint === 1,
      // converting from null to empty string is important
      // for comparison to show 'unsaved changes' message correctly
      reprintorder_id: order.reprintorder_id === null ? '' : order.reprintorder_id.toString(),
      reprintcause_id: order.reprintcause_id === null ? '' : order.reprintcause_id.toString(),
      reprintRemarks: order.reprintRemarks === null ? '' : order.reprintRemarks,
    };
    state.currentOrderPriceInfo = {
      creationsPriceWithoutVAT: CentsToEuros(order.magazinesPriceWithoutVAT),
      shippingPriceWithoutVAT: CentsToEuros(order.shippingPriceWithoutVAT),
      totalPriceWithVAT: CentsToEuros(order.totalPriceWithVAT),
      paymentCostPercentage: order.paymentCostsPercentage,
      paymentCostWithoutVAT: CentsToEuros(order.paymentCostsWithoutVAT),
      totalHighVAT: CentsToEuros(order.totalHighVAT),
      totalLowVAT: CentsToEuros(order.totalLowVAT),
      amount: order.amount,
      productType: order.printingtype === null ? '-' : `${order.printingtype.name}
            (${order.pageCount} Pages)`,
      orderextra_ids: order.orderextra_ids === null ? [] : OrderExtraIDS_Parser(order.orderextra_ids),
      orderextras: order.orderextras,
      orderextrashipping: order.orderextrasShippingWithoutVAT,
    };
    state.currentOrderShippingMethod = DetermineShippingMethod(order);
  },
};

// Turn orderextra_ids string from database into int Array
function OrderExtraIDS_Parser (orderextra_ids) {
  return orderextra_ids.split(',').map((x) => parseInt(x));
}

// Determine the shipping method for this order
function DetermineShippingMethod(order) {
  let orderextra_array = order.orderextra_ids === null ? [] : OrderExtraIDS_Parser(order.orderextra_ids);

  if (order.printingtype !== null ||
        orderextra_array.some(e => [3, 4, 5, 6, 7, 8].includes(e))) {
    if (order.shippingPriceWithoutVAT !== 0 ||
            order.orderextrasShippingWithoutVAT !== 0)
      return 'Shipment';

    else return 'Pick Up';

  }
  else return 'Digital';
}

export default {
  state,
  getters,
  actions,
  mutations,
};
