<template>
  <tr class="wordlimit z-10">
    <td class="px-6 py-4 wordlimit">
      <div class="flex items-center">
        <div>
          <div class="text-sm font-medium text-gray-900">
            {{ tableRow.fullName }}
          </div>
          <div class="text-sm text-gray-500">
            {{ tableRow.email }}
          </div>
        </div>
      </div>
    </td>
    <td class="px-6 py-4 wordlimit">
      <div class="flex items-center">
        <div>
          <div class="text-sm text-gray-500">
            Phone: {{ tableRow.phoneNumber }}
          </div>
          <div class="text-sm text-gray-500">
            Mobile: {{ tableRow.mobileNumber }}
          </div>
        </div>
      </div>
    </td>
    <td class="px-6 py-4 wordlimit text-sm text-gray-500">
      <div>{{ tableRow.address.line1 }}</div>
      <div>{{ tableRow.address.line2 }}</div>
    </td>
    <td class="px-6 py-4 wordlimit text-sm text-gray-500 whitespace-pre-wrap">
      <div>{{ tableRow.registrationDate }}</div>
    </td>
    <td class="px-6 py-4 flex wordlimit text-sm text-gray-500">
      <button
        class="bg-gray-200 hover:bg-gray-300 active:bg-gray-400 active:text-white
              text-gray-600 test-xs text-center rounded px-2 flex mr-3"
        @click="LoginAsMaker"
      >
        <SVGIcon iconStyle="mt-1.5" transform="scale(-1 1)"
                 d="M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1"
        />
        <span class="font-semibold text-sm leading-5 mx-2 my-2 whitespace-nowrap">
          Login
        </span>
      </button>
      <button
        class="bg-gray-200 hover:bg-gray-300 active:bg-gray-400 active:text-white
              text-gray-600 test-xs text-center rounded px-2 flex"
        @click="EnableDeactivateMakerModal"
      >
        <SVGIcon iconStyle="mt-1.5" transform="scale(-1 1)"
                 d="M6 18L18 6M6 6l12 12"
        />
        <span class="font-semibold text-sm leading-5 mx-2 my-2 whitespace-nowrap">
          Delete
        </span>
      </button>
    </td>

    <MakerSearchDeactivateModalVue v-if="DeactivateUserModalEnabledID === tableRow.id" :makerID="tableRow.id" :makerEmail="tableRow.email" />
  </tr>
</template>

<script>
import SVGIcon from '../../components/SVGIcon.vue';
import MakerSearchDeactivateModalVue from './MakerSearchDeactivateModal.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'MakerTableRow',
  components: { SVGIcon, MakerSearchDeactivateModalVue },
  props: { tableRow: Object },
  computed: mapGetters(['DeactivateUserModalEnabledID']),
  methods: {
    async LoginAsMaker () {
      await this.$store.dispatch('loginAsMaker', this.tableRow.id);
    },

    EnableDeactivateMakerModal() {
      this.$store.commit('SET_DEACTIVATEUSERMODAL_ID_ENABLED', this.tableRow.id);
    },
  },
};
</script>