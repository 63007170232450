<template>
  <aside class="fixed right-0 top-16
        bg-gray-600 text-gray-200 w-80 rounded-sm shadow-sm py-4 px-4 max-h-screen-90 overflow-auto"
  >

    <!-- Header -->
    <div class="flex">
      <SVGIcon iconStyle="mr-2" d="M5 8h14M5 8a2 2 0 110-4h14a2 2 0 110 4M5 8v10a2 2 0 002 2h10a2 2 0 002-2V8m-9 4h4" />
      <h1 class="font-extrabold whitespace-nowrap text-white">History: Page {{ PageHistoryInfo.number }}</h1>
      <button @click="ClosePageHistory"
              class="rounded focus:outline-none focus:shadow-outline flex hover:bg-gray-500 ml-24"
      >
        <SVGIcon d="M6 18L18 6M6 6l12 12" />
      </button>
    </div>

    <!-- Version list -->
    <div v-for="(version, index) in versions" :key="index">
      <button v-if="index < maxversions"
              class="flex border py-2 px-2 whitespace-nowrap my-2 active:bg-gray-400 cursor-pointer w-full"
              :class="version === selectedVersion ? 'bg-gray-800' : 'hover:bg-gray-500'"
              @click="selectedVersion === version ? selectedVersion = '' : selectedVersion = version"
      >
        <SVGIcon d="M9 5l7 7-7 7" />
        <span>{{ version }}</span>
      </button>
    </div>

    <!-- Button to show more if versions exceeds max -->
    <button v-if="versions.length > maxversions" class="italic" @click="maxversions = versions.length">
      Show {{ versions.length - maxversions }} more
    </button>

    <!-- Button to revert shown versions to normal amount -->
    <button v-if="maxversions > 5" class="italic" @click="maxversions = 5">
      Show less
    </button>

    <!-- Restore Page to chosen version Button -->
    <div class="flex mt-4 right-4">
      <!-- Button -->
      <button
        class="flex text-gray-600 items-center rounded px-3"
        :class="selectedVersion === '' ? 'bg-gray-500 cursor-default' : 'bg-white hover:bg-gray-200 active:bg-gray-300'"
        :disabled="selectedVersion === ''"
        @click="RestorePage"
      >
        <SVGIcon d="M8 7H5a2 2 0 00-2 2v9a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-3m-1 4l-3 3m0 0l-3-3m3 3V4" />
        <span class="font-semibold text-sm leading-5 mx-2 my-2">
          Restore Page
        </span>
      </button>

      <!-- Succes display -->
      <p v-if="showSuccesfullRestore"
         class="px-3 pt-1.5 whitespace-nowrap text-green-300 font-semibold"
      >
        Restored!
      </p>

    </div>


  </aside>
</template>


<script>
import { mapGetters } from 'vuex';
import SVGIcon from '../../../components/SVGIcon.vue';

export default {
  name: 'PageHistorySideBar',
  components: { SVGIcon },
  data () {
    return {
      versions: [],
      selectedVersion: '',
      maxversions: 5,
      showSuccesfullRestore: false,
    };
  },
  watch: {
    PageHistoryInfo() { this.SetVersions(); },
    selectedVersion() { this.showSuccesfullRestore = false; },
  },
  computed: mapGetters(['PageHistoryInfo']),
  methods: {
    ClosePageHistory() {
      this.$store.dispatch('ShowPageHistory', this.PageHistoryInfo);
    },

    RestorePage() {
      let result = this.$store.dispatch('RestorePageHistory', this.GetTimestampFromVersion());

      if (result) this.showSuccesfullRestore = true;
    },

    GetTimestampFromVersion() {
      let timestamp = '';
      this.PageHistoryInfo.history.forEach(element => {
        if (element.version === this.selectedVersion) {
          timestamp = element.timestamp;
        }
      });
      return timestamp;
    },

    SetVersions() {
      this.selectedVersion = '';
      this.versions = [];
      this.maxversions = 5;
      this.PageHistoryInfo.history.forEach(element => {
        this.versions.push(element.version);
      });
    },
  },
  beforeMount () {
    this.SetVersions();
  },
};
</script>

<style scoped>
    .max-h-screen-90 {
        max-height: 90vh;
    }
</style>