import httpClient from '/httpClient';

const change_reprintStatus = (order_id, isReprint, reprintorder_id, reprintcause_id, reprintRemarks) =>
  httpClient.post('orders/change/reprint/' + order_id, { isReprint, reprintorder_id, reprintcause_id, reprintRemarks });

const change_paymentmethod = (order_id, paymentmethod_id) =>
  httpClient.post('orders/change/' + order_id, { paymentmethod_id });

const change_orderstatus = (order_id, orderstatus_id, job_id) =>
  httpClient.post('orders/change/' + order_id, { orderstatus_id, job_id });

const search_ID_All = (id) =>
  httpClient.get('orders/search/id/full/' + id);

export { change_reprintStatus, change_paymentmethod, change_orderstatus, search_ID_All };