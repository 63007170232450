<template>
  <Modal>
    <!-- Header -->
    <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
      <div class="sm:flex sm:items-start">
        <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12
                rounded-full gray-tag sm:mx-0 sm:h-10 sm:w-10"
        >
          <!-- Heroicon name: folder-add -->
          <SVGIcon d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z" />
        </div>
        <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
          <!-- Title -->
          <h3 class="text-lg leading-6 font-medium text-gray-900 mt-1.5" id="modal-title">
            Add:
          </h3>

          <!-- Input Field -->
          <div class="mt-2">
            <input
              class="shadow appearance-none border rounded w-full py-2 px-3
                            text-gray-700 placeholder-gray-400
                            leading-tight focus:outline-none focus:shadow-outline"

              type="text"
              name="NewFolderName"
              id="NewFolderNameInput"
              placeholder="New Folder Name"
              @keyup.enter="AddDirectory"
              v-model="newFolderName"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- Footer -->
    <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
      <button type="button" @click="AddDirectory"
              class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-500 hover:bg-blue-700 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-3 sm:w-auto sm:text-sm"
      >
        <span class="text-left">Add</span>
        <SVGIcon v-if="!dataLoaded"
                 iconStyle="animate-reverse-spin ml-2 h-5"
                 d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
        />

      </button>
      <button type="button" @click="HideModal"
              class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
      >
        Cancel
      </button>
    </div>

  </Modal>
</template>

<script>
import { mapGetters } from 'vuex';
import SVGIcon from '../../../components/SVGIcon.vue';
import Modal from '../../../components/Modal.vue';

export default {
  name: 'AddFolderModal',
  components: {
    SVGIcon,
    Modal,
  },
  data () {
    return {
      newFolderName: '',
      dataLoaded: true,
    };
  },
  computed: mapGetters(['AddFolderModalEnabled']),
  methods: {
    async AddDirectory() {
      this.dataLoaded = false;
      await this.$store.dispatch('AddDirectory', this.newFolderName);
      this.dataLoaded = true;
      this.newFolderName = '';
    },

    HideModal() {
      this.$store.commit('SET_ADDFOLDERMODAL_ENABLED', false);
    },
  },
  mounted() {
    const inputField = document.getElementById('NewFolderNameInput');
    inputField.focus();
    inputField.select();
  },
};
</script>
