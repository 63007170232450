import router from '@/router';
import { search_ID_Full, restore_PageHistory } from './creationViewEndpoints';
import { ReadableDateTimeFormat } from '@/Helpers/helpers';

const state = {
  currentCreation: {},
  chiefMakers: [],
  showPageHistory: false,
  currentPageHistoryInfo: null,
};

const getters = {
  CreationInfo: (state) => {
    return state.currentCreation;
  },
  ChiefMakers: (state) => {
    return state.chiefMakers;
  },
  ShowPageHistory: (state) => {
    return state.showPageHistory;
  },
  PageHistoryInfo: (state) => {
    return state.currentPageHistoryInfo;
  },
};

const actions = {
  async getCreation({ commit }, Creation_id) {

    commit('SET_SHOWPAGEHISTORY', false);
    commit('SET_PAGEHISTORYINFO', null);

    const response = await search_ID_Full(Creation_id);

    //Check if Creation id exists
    if (response.data.message === 'No Results')
      await router.push('/creations');

    //set Creation object
    else {
      commit('SET_CREATION', response.data.magazines[0]);
      commit('SET_CHIEFMAKERS', response.data.magazines[0]);
    }
  },

  async GoToMaker({ dispatch }, email) {
    await dispatch('searchMakers', {
      searchOption: 'E-mail',
      searchText: email,
    });

    // Get maker href and open it in a new tab
    let routeData = router.resolve('/makers');
    window.open(routeData.href, '_blank');
  },

  async ShowPageHistory({ state, commit }, pageInfo) {
    if (state.currentPageHistoryInfo === null ? false : state.currentPageHistoryInfo.id === pageInfo.id) {
      commit('SET_SHOWPAGEHISTORY', false);
      commit('SET_PAGEHISTORYINFO', null);
    }
    else {
      commit('SET_SHOWPAGEHISTORY', true);
      commit('SET_PAGEHISTORYINFO', pageInfo);
    }
  },

  async RestorePageHistory({ state }, timestamp) {
    try {
      await restore_PageHistory(state.currentPageHistoryInfo.id, timestamp);
      return true;
    }
    catch {
      return false;
    }
  },

};

const mutations = {
  SET_CREATION(state, Creation) {
    state.currentCreation = Creation;

    // converting dates to readable format
    state.currentCreation.lastContentChange = ReadableDateTimeFormat(Creation.lastContentChange);
    state.currentCreation.created_at = ReadableDateTimeFormat(Creation.created_at);
    state.currentCreation.magazinepages.forEach(element => {
      element.updated_at = ReadableDateTimeFormat(element.updated_at);
      element.history.forEach(element => {
        element.version = ReadableDateTimeFormat(element.version);
      });
    });

    // sorting pages by number
    state.currentCreation.magazinepages.sort((a, b) => (a.number - b.number));
  },
  SET_CHIEFMAKERS(state, Creation) {
    var chiefMakers = [];

    Creation.users.forEach(element => {
      if (element.pivot.role_id === 2) chiefMakers.push(element.email);
    });

    state.chiefMakers = chiefMakers;
  },
  SET_SHOWPAGEHISTORY(state, value) {
    state.showPageHistory = value;
  },
  SET_PAGEHISTORYINFO(state, page) {
    state.currentPageHistoryInfo = page;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
