// Convert database Cents to euros
const CentsToEuros = (cents) => {
  return EUR_formatter.format(cents / 100);
};

// Cents to euros formatter
const EUR_formatter = new Intl.NumberFormat('en-NL', {
  style: 'currency',
  currency: 'EUR',
});

// convert database date format to more readable format (date + time)
const ReadableDateTimeFormat = (oldDateFormat, interfix = ' at ') => {
  if (oldDateFormat === undefined || oldDateFormat === null) return '-';

  let newdateFormat;

  // Check for laravel format with T
  if (oldDateFormat.includes('T'))
    newdateFormat = oldDateFormat.split('.')[0].split('T');

  // Else expect normal format with space in between
  else newdateFormat = oldDateFormat.split(' ');

  return AmericanToEuropeanDates(newdateFormat[0]) + interfix + newdateFormat[1];
};

// convert database date format to more readable format (date)
const ReadableDateFormat = (oldDateFormat) => {
  if (oldDateFormat === undefined || oldDateFormat === null) return '-';

  let newdateFormat = oldDateFormat.slice(0, 10);

  return AmericanToEuropeanDates(newdateFormat);
};

//convert the American database date format to the european date format
function AmericanToEuropeanDates(americanDate) {
  let dateArray = americanDate.split('-');
  return dateArray[2] + '-' + dateArray[1] + '-' + dateArray[0];
}


export {
  CentsToEuros,
  ReadableDateFormat,
  ReadableDateTimeFormat,
};