<template>
  <div class="flex justify-center mt-24">
    <div class="w-full max-w-md">

      <div class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
        <div class="justify-between flex">
          <h1 class="text-gray-700 font-bold text-2xl pb-6">Admin Log In</h1>
          <img class="h-10 w-auto" src="./../../assets/JIL_Mark(Round).png" alt="Jilster">
        </div>

        <div class="mb-4">
          <label class="block text-gray-700 text-sm font-bold mb-2" for="email">
            Email:
          </label>
          <input
            class="shadow appearance-none border rounded w-full py-2 px-3
            text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="email"
            name="email"
            @keyup.enter="login"
            v-model="email"
          />
        </div>


        <div class="mb-4">
          <label class="block text-gray-700 text-sm font-bold mb-2" for="password">
            Password:
          </label>
          <input
            class="shadow appearance-none border rounded w-full py-2 px-3
            text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="password"
            name="password"
            @keyup.enter="login"
            v-model="password"
          />

        </div>

        <div class="flex items-center justify-between">
          <button
            class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded
          focus:outline-none focus:shadow-outline"
            @click="login"
          >
            Sign In
          </button>
          <p v-if="showError" class="text-red-500 text-s">Email or Password is incorrect</p>
        </div>

      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'Login',
  components: {},
  data() {
    return {
      email: '',
      password: '',
      showError: false,
    };
  },
  methods: {
    async login() {
      let admin = {
        email: this.email,
        password: this.password,
      };

      this.showError = false;

      // Attempt Login
      let result = await this.$store.dispatch('login', admin);

      if (!result) this.showError = true;
    },
  },
  beforeMount() {
    if (this.$store.getters.isAuthenticated) {
      this.$router.push('/orders');
    }
  },
};
</script>
