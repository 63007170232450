import { search_ID, search_Email, search_Name, search_PostalCode, search_RecentOrders }
  from './orderSearchEndpoints';

import { CentsToEuros, ReadableDateTimeFormat } from '@/Helpers/helpers';

const state = {
  orderTableRows: [],
  lastOrderSearch: { searchOption: '', searchText: '', searchLimit: 50 },
};

const getters = {
  OrderTableRows: (state) => { return state.orderTableRows; },
  LastOrderSearch: (state) => { return state.lastOrderSearch; },
};

const actions = {
  async searchOrders({ commit }, { searchOption, searchText, searchLimit }) {
    // Variables
    var response = null;

    // Execute API request based on the chosen searchOption
    switch (searchOption) {
      case 'Order ID':
        response = await search_ID(parseInt(searchText));
        break;
      case 'E-mail':
        response = await search_Email(searchText, searchLimit);
        break;
      case 'Name':
        response = await search_Name(searchText, searchLimit);
        break;
      case 'Postal Code':
        response = await search_PostalCode(searchText);
        break;
      case 'Recent':
        response = await search_RecentOrders(searchLimit);
        break;
      default:
        commit('RESET_ORDERTABLE');
        return false;
    }

    // Check if there are results
    if (response.data.message !== 'Found') {
      commit('RESET_ORDERTABLE');
      return false;
    }

    commit('POPULATE_ORDERTABLE', response.data.order);
    commit('SET_LASTORDERSEARCH', { searchOption: searchOption, searchText: searchText, searchLimit: searchLimit });
    return true;
  },
};

const mutations = {
  RESET_ORDERTABLE(state) {
    state.orderTableRows = [];
  },
  POPULATE_ORDERTABLE(state, orders) {
    state.orderTableRows = [];

    orders.forEach((element) => {
      state.orderTableRows.push({
        id: element.id,
        fullName: element.firstName + ' ' + element.lastName,
        email: element.user.email,
        creationTitle: element.magazine.title,
        price: CentsToEuros(element.totalPriceWithVAT),
        date: ReadableDateTimeFormat(element.created_at, '\n   '),
        status: element.orderstatus.title === 'transferred' ? 'printing' : element.orderstatus.title,
        printingType: element.printingtype !== null ?
          element.printingtype.name :
          alternativePrintingType(element.orderextra_ids),
      });
    });
  },
  SET_LASTORDERSEARCH(state, lastSearch) {
    state.lastOrderSearch = lastSearch;
  },
};

// alternative description for digital or poster orders
function alternativePrintingType(orderextra_ids) {
  // Turn orderextra_ids string from database into int Array
  try {
    orderextra_ids = orderextra_ids.split(',').map((x) => parseInt(x));
  }
  catch {
    return '';
  }


  // loop through all orderextra_ids and return aproriate description
  for (let i = 0; i < orderextra_ids.length; i++) {
    switch (orderextra_ids[i]) {
      case 1: return 'PDF (72 dpi)';
      case 2: return 'Cover (300 dpi)';
      case 3: return 'Poster A0';
      case 4: return 'Poster A1';
      case 5: return 'Poster A2';
      case 6: return 'Poster A3+';
      case 7: return 'Poster A3';
      case 8: return 'Poster A4';
    }
  }
}

export default {
  state,
  getters,
  actions,
  mutations,
};
