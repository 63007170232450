<template>
  <Modal>
    <!-- Header -->
    <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
      <div class="sm:flex sm:items-start">
        <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12
                rounded-full orange-tag sm:mx-0 sm:h-10 sm:w-10"
        >
          <!-- Heroicon name: exclamation -->
          <SVGIcon d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
        </div>
        <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
          <!-- Title -->
          <h3 class="text-lg leading-6 font-medium text-gray-900 mt-1.5" id="modal-title">
            Deactivate: {{ CurrentSelectedItems.length }} item{{ CurrentSelectedItems.length > 1 ? 's' : '' }}
          </h3>

          <!-- Confirmation text Field -->
          <div class="mt-2">
            <p>
              Are You sure you want to deactivate {{ CurrentSelectedItems.length > 1 ? 'these' : 'this' }} item{{ CurrentSelectedItems.length > 1 ? 's' : '' }}?
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- Footer -->
    <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
      <!-- Deactivate Directory Button -->
      <button type="button" @click="DeactivateContent"
              class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
      >
        <span class="text-left">Deactivate</span>
        <SVGIcon v-if="!dataLoaded"
                 iconStyle="animate-reverse-spin ml-2 h-5"
                 d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
        />

      </button>

      <!-- Cancel Button -->
      <button type="button" @click="HideModal"
              class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
      >
        Cancel
      </button>
    </div>

  </Modal>
</template>

<script>
import { mapGetters } from 'vuex';
import SVGIcon from '../../../components/SVGIcon.vue';
import Modal from '../../../components/Modal.vue';

export default {
  name: 'DeactivateContentModal',
  components: {
    SVGIcon,
    Modal,
  },
  data () {
    return { dataLoaded: true };
  },
  computed: mapGetters(['DeactivateContentModalEnabled', 'CurrentSelectedItems']),
  methods: {
    async DeactivateContent() {
      this.dataLoaded = false;
      await this.$store.dispatch('ChangeActivationContent', 0);
      this.dataLoaded = true;
    },

    HideModal() {
      this.$store.commit('SET_DEACTIVATECONTENTMODAL_ENABLED', false);
    },
  },
};
</script>
