<template>
  <div class="mx-0.5" :class="disabled ? '' : 'has-tooltip'">

    <span :class="'tooltip bg-gray-400 text-white rounded-full px-2 -mt-7 ' + tooltipOffset">
      {{ tooltip }}
    </span>

    <button class="border  rounded-md px-2 py-1"
            @click="onClick"
            :class="disabled ?
              'text-gray-200 border-gray-200 cursor-default' :
              'hover:bg-gray-400 hover:text-white border-gray-400'"

            :disabled="disabled"
    >
      <SVGIcon :d="svgD" />
    </button>
  </div>
</template>

<script>
import SVGIcon from '../../components/SVGIcon.vue';

export default {
  name: 'TooltipButton',
  components: { SVGIcon },
  props: {
    onClick: { type: Function, required: true },
    disabled: Boolean,
    tooltip: String,
    svgD: String,
    tooltipOffset: String,
  },
};
</script>

<style scoped>
.tooltip {
  @apply invisible absolute;
}

.has-tooltip:hover .tooltip {
  @apply visible z-50;
}
</style>
