import httpClient from '../../../httpClient';

const search_Email = (email, limit) =>
  httpClient.get('users/search/email/' + email, { params: { limit } });

const search_Name = (name, limit) =>
  httpClient.get('users/search/name/' + name, { params: { limit } });

const search_PostalCode = (postalcode) =>
  httpClient.get('users/search/postalcode/' + postalcode);

const search_Recent = (limit) =>
  httpClient.get('users/recent', { params: { limit } });

const getBearerToken = (id) =>
  httpClient.get('/bearertoken/' + id);

const updateActive_Maker = (id, active) =>
  httpClient.put(`/users/changeActivation/${id}/${active ? 1 : 0}`);

export { search_Email, search_Name, search_PostalCode, search_Recent, getBearerToken, updateActive_Maker };