import router from '../../router';
import { login, logout } from './authEndpoints';

const state = {
  token: '',
  admin: '',
};


const getters = {
  isAuthenticated: state => !!state.token,
  getToken: state => state.token,
  getLoggedInAdmin: state => state.admin,
};

const actions = {
  async login({ commit }, admin) {
    try {
      // Attempt Login
      const response = await login(admin.email, admin.password, 'Jilster Admin');

      // Update the store
      commit('SET_TOKEN', response.data.token);
      commit('SET_ADMIN', response.data.user);

      // Push user to orders
      await router.push('/orders');
    }
    catch {
      return false;
    }

    return true;
  },

  async logout({ commit }) {
    // Clear store and localstorage
    commit('SET_TOKEN', '');
    commit('SET_ADMIN', '');
    commit('RESET_ORDERTABLE');
    commit('RESET_MAKERTABLE');
    commit('RESET_CREATIONTABLE');
    localStorage.clear();

    try {
      await logout();
    }
    finally {
      // Push user back to login page
      router.push('/login');
    }
  },
};

const mutations = {
  SET_ADMIN(state, admin) {
    state.admin = admin;
  },

  SET_TOKEN(state, token) {
    state.token = token;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
