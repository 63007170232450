<template>
  <div id="app">
    <div v-if="isAuthenticated">
      <NavBar />
    </div>
    <router-view />
  </div>
</template>

<script>
import NavBar from './domains/navigation/NavBar.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'App',
  components: { NavBar },
  computed: mapGetters(['isAuthenticated']),
};
</script>