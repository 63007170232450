import Vue from 'vue';
import App from './App.vue';
import './css/index.css';
import router from './router';
import store from './store';
import axios from 'axios';

axios.defaults.withCredentials = true;
axios.defaults.baseURL = '';

Vue.config.productionTip = false;

// Importing the global css file
import './css/global.css';

new Vue({
  store,
  render: h => h(App),
  router,
}).$mount('#app');
