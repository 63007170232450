import axios from 'axios';
import store from './src/store';

const httpClient = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: { 'Content-Type': 'application/json' },
});

const authInterceptor = (config) => {
  config.headers.Authorization = `Bearer ${store.getters.getToken}`;
  return config;
};

// interceptor to catch errors
const errorInterceptor = error => {
  // check if it's a server error
  if (!error.response) {
    return Promise.reject(error);
  }

  // all the other error responses
  switch (error.response.status) {
    case 400:
      console.error(error.response.status, error.message);
      break;

    case 401: // authentication error, logout the user if logged in
      if (store.getters.isAuthenticated) {
        store.dispatch('logout');
      }
      break;

    default:
      console.error(error.response.status, error.message);
  }
  return Promise.reject(error);
};

// Interceptor for responses
const responseInterceptor = response => {
  switch (response.status) {
    case 200:
    // yay!
      break;
    // any other cases
    default:
        // default case
  }

  return response;
};

httpClient.interceptors.response.use(responseInterceptor, errorInterceptor);

httpClient.interceptors.request.use(authInterceptor);

export default httpClient;
