<template>
  <tr class="wordlimit">
    <td class="px-6 py-4 wordlimit">
      <div class="text-sm text-gray-900">#{{ tableRow.id }}</div>
    </td>
    <td class="px-6 py-4 wordlimit">
      <div class="flex items-center">
        <div>
          <div class="text-sm max-w font-medium text-gray-900">
            {{ tableRow.fullName }}
          </div>
          <div class="text-sm text-gray-500">
            {{ tableRow.email }}
          </div>
        </div>
      </div>
    </td>
    <td class="px-6 py-4 wordlimit">
      <div class="flex items-center">
        <div>
          <div class="text-sm font-medium text-gray-900">
            {{ tableRow.creationTitle }}
          </div>
          <div class="text-sm text-gray-500">
            {{ tableRow.printingType }}
          </div>
        </div>
      </div>
    </td>
    <td class="px-6 py-4 wordlimit text-sm text-gray-500">
      {{ tableRow.price }}
    </td>
    <td class="px-6 py-4 wordlimit text-sm text-gray-500 whitespace-pre-wrap">
      {{ tableRow.date }}
    </td>
    <td class="px-6 py-4 flex justify-center items-center">
      <div :class="statusStyle" class="rounded-full text-center font-semibold uppercase text-sm py-1 px-2">
        {{ tableRow.status }}
      </div>
    </td>
    <td class="px-6 py-4 wordlimit text-center text-sm font-medium">
      <router-link
        :to="'/orders/' + tableRow.id"
        class="bg-white text-black test-xs hover:bg-gray-200 active:bg-gray-400
            border border-gray-700 rounded px-1.5 flex w-20"
      >
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 mt-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round"
                stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
          />
          <path stroke-linecap="round" stroke-linejoin="round"
                stroke-width="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
          />
        </svg>
        <span class="font-semibold text-sm leading-4 ml-1 my-2">View</span>
      </router-link>
    </td>
  </tr>
</template>

<script>
export default {
  name: 'OrderTableRow',
  props: { tableRow: Object },
  data () {
    return { statusStyle: '' };
  },
  beforeMount () {
    switch (this.tableRow.status) {
      case 'new':
        this.statusStyle = 'orange-tag';
        break;
      case 'paid':
        this.statusStyle = 'orange-tag';
        break;
      case 'generated':
        this.statusStyle = 'orange-tag';
        break;
      case 'transferred':
        this.statusStyle = 'orange-tag';
        break;
      case 'printing':
        this.statusStyle = 'orange-tag';
        break;
      case 'sent':
        this.statusStyle = 'green-tag';
        break;
      case 'cancelled':
        this.statusStyle = 'gray-tag';
        break;
    }
  },
};
</script>

