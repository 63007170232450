<template>
  <div class="bg-white shadow-md rounded px-4 pt-6 pb-8 mb-1 pl-14">
    <table>
      <td class="text-gray-500 uppercase font-bold">
        <tr>Name</tr>
        <tr>Email</tr>
        <tr>Phone Number</tr>
        <tr>Mobile Number</tr>

        <tr>&nbsp;</tr>

        <tr>Address</tr>
        <tr>Postal Code</tr>
        <tr>Shipping Method</tr>

        <tr>&nbsp;</tr>

        <tr>Creation</tr>
        <tr>Sample</tr>
        <tr>Printing Type</tr>
        <tr>Zip file</tr>

        <tr>&nbsp;</tr>

        <tr>Order Date</tr>
        <tr>Order Status</tr>
        <tr>Payment Method</tr>

        <tr>&nbsp;</tr>

        <tr>Reprint</tr>
      </td>
      <td class="px-20 text-gray-600 ">
        <tr>{{ OrderInfo.fullName }}</tr>
        <tr>{{ OrderInfo.email }}</tr>
        <tr>{{ OrderInfo.phoneNumber }}</tr>
        <tr>{{ OrderInfo.mobileNumber }}</tr>

        <tr>&nbsp;</tr>

        <tr>{{ OrderInfo.addres }}</tr>
        <tr>{{ OrderInfo.postalCode }}</tr>
        <tr>{{ OrderShippingMehod }}</tr>

        <tr>&nbsp;</tr>

        <tr>{{ OrderInfo.creation }}</tr>
        <tr class="px-7 whitespace-nowrap text-sm text-gray-500">
          <SVGIcon v-if="OrderInfo.proof === 1" iconStyle="text-green-500"
                   d="M5 13l4 4L19 7"
          />
          <SVGIcon v-else iconStyle="text-gray-500"
                   d="M6 18L18 6M6 6l12 12"
          />
        </tr>
        <tr>{{ OrderInfo.printingType }}</tr>
        <tr>
          <a v-if="OrderInfo.fileUrl !== null"
             :href="OrderInfo.fileUrl"
             class="bg-white hover:bg-gray-200 text-gray-600 font-bold px-1 rounded inline-flex items-center"
             target="_blank"
          >
            <span>Download</span>
            <SVGIcon iconStyle="ml-2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
          </a>
          <div v-else class="font-bold">
            No File found
          </div>
        </tr>

        <tr>&nbsp;</tr>

        <tr>{{ OrderInfo.date }}</tr>
        <tr>
          <!-- When order is transfering, printing or sent -> display status  -->
          <span v-if="[4, 5, 6].includes(OrderInfo.orderstatus_id)" class="uppercase">
            {{ OrderInfo.status }}
          </span>

          <!-- When order is new -> select option to cancelled or paid -->
          <select v-if="[1].includes(OrderInfo.orderstatus_id)"
                  class="shadow appearance-none border rounded pl-1 pr-6
                    text-gray-700 placeholder-gray-400
                    leading-tight focus:outline-none focus:shadow-outline select-arrows"
                  name="orderstatus_id_select_1"
                  v-model="orderstatusIdChange"
          >
            <option class="w-full mt-2" :value="OrderInfo.orderstatus_id">{{ OrderInfo.status }}</option>
            <option class="w-full mt-2" value="2">PAID</option>
            <option class="w-full mt-2" value="7">CANCELLED</option>
          </select>

          <!-- When order is paid -> select option to cancelled -->
          <select v-if="[2, 3].includes(OrderInfo.orderstatus_id)"
                  class="shadow appearance-none border rounded pl-1 pr-6
                    text-gray-700 placeholder-gray-400
                    leading-tight focus:outline-none focus:shadow-outline select-arrows"
                  name="orderstatus_id_select_2"
                  v-model="orderstatusIdChange"
          >
            <option class="w-full mt-2" :value="OrderInfo.orderstatus_id">{{ OrderInfo.status }}</option>
            <option class="w-full mt-2" value="7">CANCELLED</option>
          </select>

          <!-- When order is cancelled -> select option to paid -->
          <select v-if="[7].includes(OrderInfo.orderstatus_id)"
                  class="shadow appearance-none border rounded pl-1 pr-6
                    text-gray-700 placeholder-gray-400
                    leading-tight focus:outline-none focus:shadow-outline select-arrows"
                  name="orderstatus_id_select_3"
                  v-model="orderstatusIdChange"
          >
            <option class="w-full mt-2" :value="OrderInfo.orderstatus_id">{{ OrderInfo.status }}</option>
            <option class="w-full mt-2" value="2">PAID</option>
          </select>

        </tr>

        <tr class="justify-between flex">
          <select
            class="shadow appearance-none border rounded pl-1 pr-6
                    text-gray-700 placeholder-gray-400
                    leading-tight focus:outline-none focus:shadow-outline select-arrows"

            name="paymentmethod_id_select"
            v-model="paymentmethodIdChange"
          >
            <option class="w-full mt-2" value="1">iDEAL</option>
            <option class="w-full mt-2" value="2">MasterCard</option>
            <option class="w-full mt-2" value="3">VisaCard</option>
            <option class="w-full mt-2" value="4">American Express</option>
            <option class="w-full mt-2" value="5">Bancontant/Mister Cash</option>
            <option class="w-full mt-2" value="6">PayPal</option>
            <option class="w-full mt-2" value="7">Sofortüberweisung</option>
            <option class="w-full mt-2" value="8">Giropay</option>
            <option class="w-full mt-2" value="9">Banküberweisung</option>
            <option class="w-full mt-2" value="10">Invoice</option>
            <option class="w-full mt-2" value="11">Payment Link</option>
            <option class="w-full mt-2" value="12">On Jilster</option>
          </select>
        </tr>
        <tr>&nbsp;</tr>

        <tr class="justify-between flex">
          <div class="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
            <input type="checkbox" name="toggle" id="toggle" class="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer"
                   v-model="reprintInfoChange.isReprint"
            />
            <label for="toggle" class="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"></label>
          </div>
        </tr>
      </td>
    </table>


    <table v-if="reprintInfoChange.isReprint"
           class="text-gray-600"
    >
      <tr>
        <td class="px-6 font-semibold">Original Order</td>
        <td>
          <input
            class="shadow appearance-none rounded w-full py-2 px-3
                    text-gray-700 placeholder-gray-400
                    leading-tight focus:outline-none focus:shadow-outline"
            :class="reprintOrderIdError ? 'border border-red-500' : 'border'"
            type="number"
            name="reprintOrderID"
            placeholder="Order ID of Original Order"
            v-model="reprintInfoChange.reprintorder_id"
          />
        </td>
        <td>
          <p v-if="reprintOrderIdError" class="text-red-400 ml-3">
            Invalid order ID
          </p>
        </td>
      </tr>
      <tr>
        <td class="px-6 font-semibold">Reason</td>
        <td>
          <select
            class="shadow appearance-none border rounded py-2 pl-3 pr-8
                    text-gray-700 placeholder-gray-400
                    leading-tight focus:outline-none focus:shadow-outline select-arrows"
            name="reprintCauseID"
            v-model="reprintInfoChange.reprintcause_id"
          >
            <option class="w-full mt-2" value="1">Damaged during shipment</option>
            <option class="w-full mt-2" value="2">Printing error</option>
            <option class="w-full mt-2" value="3">Cutting error</option>
            <option class="w-full mt-2" value="4">Damaged at printing company</option>
            <option class="w-full mt-2" value="5">Wrong print file</option>
            <option class="w-full mt-2" value="6">Customer error</option>
            <option class="w-full mt-2" value="7">Other</option>
          </select>
        </td>
      </tr>
      <tr>
        <td class="px-6 font-semibold">Remarks</td>
        <td>
          <textarea
            class="shadow appearance-none border rounded w-full py-2 px-3
                    text-gray-700 placeholder-gray-400
                    leading-tight focus:outline-none focus:shadow-outline"

            type="text"
            name="reprintRemarks"
            placeholder="Remarks about the reprint"
            v-model="reprintInfoChange.reprintRemarks"
          />

        </td>
      </tr>
    </table>

    <div
      class="flex items-center mt-8"
    >
      <button
        class=" text-white font-bold py-2 px-4 mx-2 rounded
            focus:outline-none focus:shadow-outline flex"
        :class="Changes ? 'bg-blue-500 hover:bg-blue-700' : 'bg-gray-300 cursor-default'"
        :disabled="!Changes"
        @click="SaveChanges"
      >
        <span>Save</span>
        <SVGIcon iconStyle="ml-2" d="M8 7H5a2 2 0 00-2 2v9a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-3m-1 4l-3 3m0 0l-3-3m3 3V4" />
      </button>
      <button
        class=" font-bold py-2 px-4 mx-2 rounded
            focus:outline-none focus:shadow-outline flex"
        :class="Changes ? 'hover:bg-red-200 text-red-500' : 'text-gray-300 cursor-default'"
        :disabled="!Changes"
        @click="SetChangeToOriginal"
      >
        <span>Undo Changes</span>
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SVGIcon from '@/components/SVGIcon.vue';

export default {
  name: 'OrderInfoTable',
  components: { SVGIcon },
  data () {
    return {
      saving: false,
      reprintInfoChange: null,
      paymentmethodIdChange: null,
      orderstatusIdChange: null,
      reprintOrderIdError: false,
    };
  },
  computed: {

    ...mapGetters([
      'OrderInfo',
      'OrderInfoReprint',
      'OrderShippingMehod',
    ]),

    Changes() {
      if (JSON.stringify(this.OrderInfoReprint) !== JSON.stringify(this.reprintInfoChange) ||
            this.OrderInfo.paymentmethod_id !== this.paymentmethodIdChange ||
            this.OrderInfo.orderstatus_id !== this.orderstatusIdChange) return true;
      else return false;
    },
  },
  methods: {

    SetChangeToOriginal () {
      this.reprintInfoChange = { ...this.OrderInfoReprint };
      this.paymentmethodIdChange = this.OrderInfo.paymentmethod_id;
      this.orderstatusIdChange = this.OrderInfo.orderstatus_id;
      this.reprintOrderIdError = false;
    },

    async SaveChanges () {
      // Spam prevention
      if (this.saving) return;

      this.saving = true;

      // Check for changes and execute code accordingly
      if (JSON.stringify(this.OrderInfoReprint) !== JSON.stringify(this.reprintInfoChange)) {
        await this.ChangeReprintProperties();
      }
      if (this.OrderInfo.paymentmethod_id !== this.paymentmethodIdChange) {
        await this.ChangePaymentMethod();
      }
      if (this.OrderInfo.orderstatus_id !== this.orderstatusIdChange) {
        await this.ChangeOrderStatus();
      }

      // Re-enable Saving
      this.saving = false;
    },

    async ChangeReprintProperties() {
      let result = await this.$store.dispatch(
        'ChangeReprintProperties',
        this.reprintInfoChange,
      );

      if (result) {
        this.reprintOrderIdError = false;
        this.reprintInfoChange = { ...this.OrderInfoReprint };

      }
      else {
        this.reprintOrderIdError = true;
      }
    },

    async ChangePaymentMethod() {
      await this.$store.dispatch(
        'ChangePaymentMethod',
        this.paymentmethodIdChange,
      );

      this.paymentmethodIdChange = this.OrderInfo.paymentmethod_id;
    },

    async ChangeOrderStatus() {
      await this.$store.dispatch(
        'ChangeOrderStatus',
        this.orderstatusIdChange,
      );

      this.orderstatusIdChange = this.OrderInfo.orderstatus_id;
    },
  },
  beforeMount () {
    this.SetChangeToOriginal();
  },
};
</script>

<style scoped>
/* CHECKBOX TOGGLE SWITCH */
/* @apply doesn't work inline */
.toggle-checkbox:checked {
  @apply right-0 border-green-400;
  right: 0;
  border-color: #68D391;
}
.toggle-checkbox:checked + .toggle-label {
  @apply bg-green-400;
  background-color: #68D391;
}
</style>