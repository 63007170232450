import {
  get_Templates, get_Backgrounds, get_ClipArt, get_Shapes,
  rename_Directory,
  add_Directory,
  updateActive_Directory,
  move_Content,
  changeactivation_Content,
  save_sortOrder_Content,
  save_sortOrder_Template,
  upload_Content,
}
  from './editorEndpoints';

const state = {
  activeEditorOption: '',
  activeCreationType: 'default',
  currentContent: [],

  currentFolders: [],
  activeFolder: '',

  currentItems: [],
  currentSelectedItems: [],

  renameFolderModalEnabled: false,
  addFolderModalEnabled: false,
  deactivateFolderModalEnabled: false,
  activateFolderModalEnabled: false,
  moveModalEnabled: false,
  deactivateContentModalEnabled: false,
  uploadImageModalEnabled: false,

  currentDirectoryType: 'imagedirectory',
};

const getters = {
  ActiveEditorOption: state => state.activeEditorOption,
  ActiveCreationType: state => state.activeCreationType,
  ActiveFolder: state => state.activeFolder,
  CurrentFolders: state => state.currentFolders,
  CurrentItems: state => state.currentItems,
  CurrentSelectedItems: state => state.currentSelectedItems,
  RenameFolderModalEnabled: state => state.renameFolderModalEnabled,
  AddFolderModalEnabled: state => state.addFolderModalEnabled,
  DeactivateFolderModalEnabled: state => state.deactivateFolderModalEnabled,
  ActivateFolderModalEnabled: state => state.activateFolderModalEnabled,
  MoveModalEnabled: state => state.moveModalEnabled,
  DeactivateContentModalEnabled: state => state.deactivateContentModalEnabled,
  UploadImageModalEnabled: state => state.uploadImageModalEnabled,
};

const actions = {
  async ChangeEditorOption({ state, commit, dispatch }, newOption) {
    // asign the active editor option name for caching
    commit('SET_ACTIVEEDITOROPTION', newOption);

    // Reset values
    commit('RESET_EDITORVALUES');

    // Get Content based on newOption
    let response;
    switch (newOption) {
      case 'Templates': response = await get_Templates(); break;
      case 'Backgrounds': response = await get_Backgrounds(); break;
      case 'Clip-Art': response = await get_ClipArt(); break;
      case 'Shapes': response = await get_Shapes(); break;
    }

    // Commit the received items to the state
    commit('SET_CURRENTCONTENT', response.data);
    // commit the new content changes
    commit('APPLY_CONTENT', response.data);

    // Set CreationType
    dispatch('ChangeCreationType', state.activeCreationType);
  },

  ChangeCreationType({ state, commit }, newCreationType) {
    // creationType is only available for clipart at the moment
    if (state.activeEditorOption !== 'Clip-Art' && state.activeEditorOption !== 'Backgrounds') {
      commit('SET_ACTIVECREATIONTYPE', null);
      return;
    }

    // set correct creationType
    commit('SET_ACTIVECREATIONTYPE', newCreationType);

    let newContent = { ...state.currentContent };

    // filter out all other creationTypes
    state.currentFolders.forEach(folderKey => {
      if (newContent[folderKey].creationType !== state.activeCreationType) {
        // null counts as the default creationType
        if (state.activeCreationType === 'default' && newContent[folderKey].creationType === null) return;
        // else delete the property
        delete newContent[folderKey];
      }
    });

    // commit the new content changes
    if (Object.keys(newContent).length !== 0) commit('APPLY_CONTENT', newContent);
    else {
      // Set temp content to avoid bugs with empty folder view
      newContent = { ...state.currentContent };
      newContent['-'] = {
        images:	[],
        id:	0,
        active:	0,
        title:	'-',
        creationType:	state.activeCreationType,
      };

      let newTempContent = {
        '-': {
          images:	[],
          id:	0,
          active:	0,
          title:	'-',
          creationType:	state.activeCreationType,
        },
      };

      commit('SET_CURRENTCONTENT', newContent);
      commit('APPLY_CONTENT', newTempContent);
    }
  },

  async RenameDirectory({ state, commit, dispatch }, newName) {

    await rename_Directory(state.activeFolder.id, newName, state.currentDirectoryType);

    // Close the rename folder modal
    commit('SET_RENAMEFOLDERMODAL_ENABLED', false);

    // Reload the page
    dispatch('ReloadEditorContent', newName);
  },

  async AddDirectory({ state, commit, dispatch }, newName) {

    await add_Directory(newName, state.activeEditorOption === 'Backgrounds' ?
      'background' : 'clipart', state.activeCreationType, state.currentDirectoryType);

    // close the add folder modal
    commit('SET_ADDFOLDERMODAL_ENABLED', false);

    // Reload the page
    dispatch('ReloadEditorContent', newName);
  },

  async UpdateDirectoryActivation({ state, commit, dispatch }, newActivationState) {
    // Check if parameter is in correct format
    if (newActivationState !== 0 && newActivationState !== 1) return;

    await updateActive_Directory(state.activeFolder.id, newActivationState, state.currentDirectoryType);

    // Close the deactivate and activate folder modal
    commit('SET_DEACTIVATEFOLDERMODAL_ENABLED', false);
    commit('SET_ACTIVATEFOLDERMODAL_ENABLED', false);

    // Reload the page
    dispatch('ReloadEditorContent', state.activeFolder.title);
  },

  async MoveDirectoryContent({ state, commit, dispatch }, moveToFolder) {
    // Get the image or shape ids from current selected items
    let selectedContentIDS = [];
    state.currentSelectedItems.forEach(element => {
      selectedContentIDS.push(element.id);
    });

    await move_Content(state.currentContent[moveToFolder].id, selectedContentIDS, state.currentDirectoryType);

    // Close the move folder content modal
    commit('SET_MOVEMODAL_ENABLED', false);

    // Reload the page
    dispatch('ReloadEditorContent', moveToFolder);
  },

  async ChangeActivationContent({ state, commit, dispatch }, active) {
    // Get the image or shape ids from current selected items
    let selectedContentIDS = [];
    state.currentSelectedItems.forEach(element => {
      selectedContentIDS.push(element.id);
    });

    await changeactivation_Content(selectedContentIDS, active, state.currentDirectoryType);

    // Close the change activation folder modal
    commit('SET_DEACTIVATECONTENTMODAL_ENABLED', false);

    // Reload the page
    dispatch('ReloadEditorContent', state.activeFolder.title);
  },

  async UploadImage({ state, commit, dispatch }, file) {

    let formData = new FormData();
    formData.append('file', file);
    formData.append('directory_id', state.activeFolder.id);

    let error = '';

    await upload_Content(formData, state.currentDirectoryType)
      .catch(err => {
        try {
          error = err.response.data.errors.file[0];
        }
        catch {
          error = 'Something went wrong';
        }
      });

    if (error !== '') return error;

    // Close the upload image modal
    commit('SET_UPLOADIMAGEMODAL_ENABLED', false);

    // Reload the page
    dispatch('ReloadEditorContent', state.activeFolder.title);

    return 'succes';

  },

  async SaveItemSorting({ state }) {
    // Get an array of all the Ids in the good sort order
    let ids = state.currentItems.map(e => e.id);

    if (state.activeEditorOption === 'Templates') save_sortOrder_Template(ids);
    else await save_sortOrder_Content(ids, state.currentDirectoryType);
  },

  async ReloadEditorContent({ state, commit, dispatch }, activeFolderTitle) {

    // Dispatch ChangeEditorOption so that the values update correctly
    await dispatch('ChangeEditorOption', state.activeEditorOption);

    // Set ActiveFolder back
    commit('SET_ACTIVEFOLDER', activeFolderTitle);
  },
};

const mutations = {
  SET_CURRENTCONTENT(state, content) {
    state.currentContent = content;
  },
  APPLY_CONTENT(state, content) {
    // The object keys are the folder names within them is the array of items
    let directoryTitles = Object.keys(content);
    state.currentFolders = directoryTitles;
    state.activeFolder = content[directoryTitles[0]];
    state.currentItems = content[directoryTitles[0]].images;
  },
  SET_ACTIVECREATIONTYPE(state, newCreationType) {
    state.activeCreationType = newCreationType;
  },
  SET_ACTIVEFOLDER(state, newFolderTitle) {
    state.activeFolder = state.currentContent[newFolderTitle];
    state.currentItems = state.currentContent[newFolderTitle].images;
  },
  SET_CURRENTITEMS(state, newItems) {
    state.currentItems = newItems;
  },
  RESET_EDITORVALUES(state) {
    state.currentFolders = [];
    state.currentItems = [];
    state.currentSelectedItems = [];
  },
  RESET_SELECTION(state) {
    state.currentSelectedItems = [];
  },
  SET_ACTIVEEDITOROPTION(state, newOption) {
    state.currentSelectedItems = [];
    state.activeEditorOption = newOption;

    // Setting the correct directory type for the endpoint urls
    state.currentDirectoryType = newOption === 'Shapes' ? 'shapedirectory' : 'imagedirectory';
  },
  SET_RENAMEFOLDERMODAL_ENABLED(state, newValue) {
    state.renameFolderModalEnabled = newValue;
  },
  SET_ADDFOLDERMODAL_ENABLED(state, newValue) {
    state.addFolderModalEnabled = newValue;
  },
  SET_DEACTIVATEFOLDERMODAL_ENABLED(state, newValue) {
    state.deactivateFolderModalEnabled = newValue;
  },
  SET_ACTIVATEFOLDERMODAL_ENABLED(state, newValue) {
    state.activateFolderModalEnabled = newValue;
  },
  SET_MOVEMODAL_ENABLED(state, newValue) {
    state.moveModalEnabled = newValue;
  },
  SET_DEACTIVATECONTENTMODAL_ENABLED(state, newValue) {
    state.deactivateContentModalEnabled = newValue;
  },
  SET_UPLOADIMAGEMODAL_ENABLED(state, newValue) {
    state.uploadImageModalEnabled = newValue;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
