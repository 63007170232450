<template>
  <Table :headers="['Product', ' ', 'Amount', 'Price']">

    <OrderPriceInfoTableRow v-if="OrderPriceInfo.productType !== '-'"
                            :title="OrderPriceInfo.productType"
                            :amount="OrderPriceInfo.amount"
                            :price="OrderPriceInfo.creationsPriceWithoutVAT"
    />
    <OrderPriceInfoTableRow v-if="OrderPriceInfo.productType !== '-'"
                            title="Shipping Cost (Creation)"
                            :price="OrderPriceInfo.shippingPriceWithoutVAT"
    />

    <!-- Order Extras -->
    <tr v-for="(id, index) in OrderPriceInfo.orderextra_ids" :key="id">
      <td class="px-6 py-4">
        <div class="text-sm text-gray-900">
          {{ GetTitle_FromOrderExtraID(id) }}
        </div>
      </td>

      <td class="px-6 py-4">&nbsp;</td>
      <td class="px-6 py-4">&nbsp;</td>

      <td class="px-6 py-4">
        <div class="text-sm text-gray-900">
          {{ orderExtrasPrices[index] }}
        </div>
      </td>
    </tr>


    <OrderPriceInfoTableRow v-if="OrderPriceInfo.paymentCostPercentage !== 0"
                            :title="`Payment Cost (${OrderPriceInfo.paymentCostPercentage}%)`"
                            :price="OrderPriceInfo.paymentCostWithoutVAT"
    />
    <OrderPriceInfoTableRow v-if="OrderPriceInfo.totalLowVAT !== '€0.00'"
                            title="BTW (9%)"
                            :price="OrderPriceInfo.totalLowVAT"
    />
    <OrderPriceInfoTableRow v-if="OrderPriceInfo.totalHighVAT !== '€0.00'"
                            title="BTW (21%)"
                            :price="OrderPriceInfo.totalHighVAT"
    />
    <OrderPriceInfoTableRow
      title="Total"
      titleStyle="font-weight: 900;"
      priceStyle="font-weight: 900;"
      :price="OrderPriceInfo.totalPriceWithVAT"
    />

  </Table>
</template>


<script>
import OrderPriceInfoTableRow from './OrderPriceInfoTableRow.vue';
import Table from '../../../components/Table.vue';
import { mapGetters } from 'vuex';
import { CentsToEuros } from '@/Helpers/helpers';

export default {
  name: 'OrderPriceInfoTable',
  components: {
    OrderPriceInfoTableRow,
    Table,
  },
  data () {
    return { orderExtrasPrices: [] };
  },
  computed: mapGetters(['OrderPriceInfo']),
  methods: {
    // Get the title for the orderextra object per id
    GetTitle_FromOrderExtraID(id) {
      switch (id) {
        case 1: return 'PDF (72 dpi)';
        case 2: return 'PDF Cover (300 dpi)';
        case 3: return 'Poster A0';
        case 4: return 'Poster A1';
        case 5: return 'Poster A2';
        case 6: return 'Poster A3-plus';
        case 7: return 'Poster A3';
        case 8: return 'Poster A4';
        case 9: return 'Poster Shipping';
        default: return '-';
      }
    },

    // Convert the orderextras JSON string in the database into:
    // prices without VAT for the table
    OrderExtras_Parser(OrderExtras) {
      var slicedString = OrderExtras.slice(1, -1);
      var priceArray = [];

      for (var i = 0; i < this.OrderPriceInfo.orderextra_ids.length; i++) {
        priceArray.push(CentsToEuros(
          JSON.parse(slicedString.substring(
            0, slicedString.indexOf('}') + 1,
          ),
          ).priceWithoutVAT,
        ));

        slicedString = slicedString.slice(slicedString.indexOf(',{') + 1);
      }

      return priceArray;
    },
  },
  beforeMount () {
    this.orderExtrasPrices = this.OrderExtras_Parser(this.OrderPriceInfo.orderextras);

    // Checking if poster shipping should be applied, from orderextrashipping
    if (this.OrderPriceInfo.orderextrashipping !== 0) {
      this.OrderPriceInfo.orderextra_ids.push(9);
      this.orderExtrasPrices.push(CentsToEuros(this.OrderPriceInfo.orderextrashipping));
    }
  },
};
</script>