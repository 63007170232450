<template>
  <div class="bg-white shadow-md rounded pt-6 pb-8 mb-1 pl-14">
    <table>
      <tr>
        <td class="text-gray-500 uppercase font-bold whitespace-nowrap">
          Canvas Count
        </td>

        <td class="px-8">{{ CreationInfo.pageCount }}</td>
      </tr>
      <tr>
        <td class="text-gray-500 uppercase font-bold">
          Orderable
        </td>

        <td class="px-7 whitespace-nowrap text-sm text-gray-500">
          <SVGIcon v-if="CreationInfo.status === 3" iconStyle="text-green-500"
                   d="M5 13l4 4L19 7"
          />
          <SVGIcon v-else iconStyle="text-gray-500"
                   d="M6 18L18 6M6 6l12 12"
          />
        </td>
      </tr>
      <tr>
        <td class="text-gray-500 uppercase font-bold">
          Shared
        </td>

        <td class="px-7 whitespace-nowrap text-sm text-gray-500">
          <SVGIcon v-if="CreationInfo.shared === 1" iconStyle="text-green-500"
                   d="M5 13l4 4L19 7"
          />
          <SVGIcon v-else iconStyle="text-gray-500"
                   d="M6 18L18 6M6 6l12 12"
          />
        </td>
      </tr>

      <tr>&nbsp;</tr>

      <tr>
        <td class="text-gray-500 uppercase font-bold">
          Canvas Version
        </td>

        <td class="px-8 uppercase">{{ CreationInfo.canvasVersion }}</td>
      </tr>

      <tr v-if="CreationInfo.canvasVersion === 'linda'">
        <td class="text-gray-500 uppercase font-bold">
          Spine Status
        </td>

        <td v-if="CreationInfo.spineStatus === 0" class="px-8 uppercase"> REMOVED </td>
        <td v-else-if="CreationInfo.spineStatus === 1" class="px-8 uppercase"> OPENED </td>
        <td v-else-if="CreationInfo.spineStatus === 2" class="px-8 uppercase"> SUBMITTED </td>
        <td v-else-if="CreationInfo.spineStatus === 3" class="px-8 uppercase"> FINISHED </td>
      </tr>

      <tr>&nbsp;</tr>

      <tr>
        <td class="text-gray-500 uppercase font-bold whitespace-nowrap">
          Last Change
        </td>

        <td class="px-8">{{ CreationInfo.lastContentChange }}</td>
      </tr>
      <tr>
        <td class="text-gray-500 uppercase font-bold whitespace-nowrap">
          Created At
        </td>

        <td class="px-8">{{ CreationInfo.created_at }}</td>
      </tr>

      <tr>&nbsp;</tr>

      <tr>
        <td class="text-gray-500 uppercase font-bold whitespace-nowrap">
          Owner
        </td>

        <td class="px-8">
          <button
            target="_blank"
            class="text-indigo-600 hover:text-indigo-900"
            @click="GoToMaker(CreationInfo.owner)"
          >
            {{ CreationInfo.owner }}
          </button>
        </td>
      </tr>
      <tr>
        <td class="text-gray-500 uppercase font-bold align-top whitespace-nowrap">
          Chief Maker
        </td>

        <td class="px-8 flex flex-wrap">
          <span v-if="ChiefMakers.length === 0">-</span>
          <div v-for="chiefMaker in ChiefMakers"
               :key="chiefMaker"
          >
            <button
              target="_blank"
              class="text-indigo-600 hover:text-indigo-900"
              @click="GoToMaker(chiefMaker)"
            >
              {{ chiefMaker }} &nbsp;&nbsp;
            </button>
          </div>
        </td>
      </tr>

      <tr>&nbsp;</tr>

      <tr>
        <td class="text-gray-500 uppercase font-bold align-top whitespace-nowrap">
          Orders made
        </td>

        <td class="px-8 flex flex-wrap">
          <span v-if="CreationInfo.orders.length === 0">-</span>
          <div v-for="order in CreationInfo.orders"
               :key="order"
          >
            <router-link
              target="_blank"
              class="text-indigo-600 hover:text-indigo-900"
              :to="'/orders/' + order"
            >
              {{ order }}&nbsp;&nbsp;
            </router-link>
          </div>
        </td>
      </tr>
    </table>

    <!-- Will be implemented later -->

    <!-- <div class="flex items-center mt-8">
            <button
            class=" bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 mx-2 rounded
            focus:outline-none focus:shadow-outline flex"
            @click="GeneratePDFCreation">
                Generate PDF Creation
            </button>
            <button
            class=" bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 mx-2 rounded
            focus:outline-none focus:shadow-outline flex"
            @click="GeneratePDFCreation">
                Generate PDF Cover
            </button>
            <button
            class=" text-white font-bold py-2 px-4 mx-2 rounded
            focus:outline-none focus:shadow-outline flex"
            :class="CreationInfo.status === 3 ? 'bg-blue-500 hover:bg-blue-700' : 'bg-gray-300 cursor-default'"
            :disabled="CreationInfo.status !== 3"
            @click="GeneratePDFCreation">
                Generate Print File
            </button>
        </div> -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SVGIcon from '@/components/SVGIcon.vue';

export default {
  name: 'CreationInfoTable',
  components: { SVGIcon },
  computed: mapGetters(['CreationInfo', 'ChiefMakers']),
  methods: {
    // GeneratePDFCreation(){ console.log("Not Implemented") },
    GoToMaker(email) {
      this.$store.dispatch('GoToMaker', email);
    },
  },
};
</script>