<template>
  <div class="flex justify-center mt-24">
    <div class="w-full" style="width: 72rem;">

      <div class="bg-white shadow-md rounded px-4 pt-6 pb-8 mb-4 ">
        <!-- Icon and header -->
        <div class="flex py-3">
          <SVGIcon :iconStyle="svgSpacing" :d="svgD" />
          <h1 class="text-gray-700 font-bold text-2xl">{{ searchName }}</h1>
        </div>

        <div class="mb-4 flex">

          <!-- SearchOptions Drop down -->
          <select
            class="shadow appearance-none border roundedpy-2 pl-2 pr-5
                      text-gray-700 placeholder-gray-400
                      leading-tight focus:outline-none focus:shadow-outline select-arrows"

            id="searchOptionSelect"
            name="searchOptionSelect"
            v-model="searchOption"
            @change="showNoResults = ''"
          >
            <option v-for="option in searchOptions" :key="option"
                    class="form-select w-full mt-2" :value="option"
            >{{ option }}</option>
          </select>

          <!-- SearchBar -->
          <input
            class="shadow appearance-none border rounded w-full py-2 px-3
                    text-gray-700 placeholder-gray-400
                    leading-tight focus:outline-none focus:shadow-outline"

            id="searchInput"
            type="text"
            name="searchInput"
            :placeholder="'Search in ' + [[searchOption]] + 's'"
            @keyup.enter="search(searchOption, searchText, searchLimit)"
            v-model="searchText"
          />

          <!-- Search Button -->
          <div class="flex items-center justify-between">
            <button
              class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded
                        focus:outline-none focus:shadow-outline flex"
              :disabled="searching"
              @click="search(searchOption, searchText, searchLimit)"
            >
              <SVGIcon v-if="searching"
                       iconStyle="animate-reverse-spin mr-2"
                       d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
              />
              <span>Search</span>
            </button>
          </div>

        </div>

        <!-- Recent search options -->
        <div class="flex justify-between mb-4">
          <div>
            <h2 v-if="showRecentSearch"
                class="text-gray-700 text-xl py-3"
            >Find Recent {{ searchName }}</h2>
            <!-- Search with recent option button -->
            <div v-if="showRecentSearch"
                 class="flex items-center justify-between"
            >
              <button
                class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded
                            focus:outline-none focus:shadow-outline flex"
                :disabled="searching"
                @click="search('Recent', '', searchLimit)"
              >
                <SVGIcon v-if="searching"
                         iconStyle="animate-reverse-spin mr-2"
                         d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
                />
                <span>Search</span>
              </button>
            </div>
          </div>


          <div>
            <h2 class="text-gray-700 text-xl py-3">Search Limiter</h2>

            <!-- Recent amount drop down -->
            <select
              class="shadow appearance-none border rounded py-2 pl-2 pr-5
                        text-gray-700 placeholder-gray-400
                        leading-tight focus:outline-none focus:shadow-outline select-arrows float-right"

              id="limitSelect"
              name="limitSelect"
              v-model="searchLimit"
            >
              <option class="form-select w-full mt-2" :value="10">10</option>
              <option class="form-select w-full mt-2" :value="50">50</option>
              <option class="form-select w-full mt-2" :value="100">100</option>
              <option class="form-select w-full mt-2" :value="200">200</option>
              <option class="form-select w-full mt-2" :value="500">500</option>
              <option class="form-select w-full mt-2" :value="1000">1000</option>
            </select>
          </div>

        </div>

      </div>

      <!-- Insert results table -->
      <slot></slot>

      <!-- No Results message -->
      <p v-if="showNoResults" class="text-gray-400 text-xl flex justify-center pt-6">No Results</p>

    </div>
  </div>
</template>

<script>
import SVGIcon from './SVGIcon.vue';

export default {
  name: 'SearchBox',
  components: { SVGIcon },
  props: {
    searchOptions: Array,
    lastSearch: Object,
    storeSearchMethod: String,
    searchName: String,
    svgD: String,
    svgSpacing: {
      type: String,
      default: 'mt-1 mr-3',
      required: false,
    },
    showRecentSearch: {
      type: Boolean,
      default: true,
      required: false,
    },
  },
  data () {
    return {
      searchText: '',
      searchOption: '',
      searchLimit: 50,
      searching: false,
      showNoResults: false,
    };
  },
  methods: {
    async search(option, text, limit) {
      // Check if searchText has a value or option is recent
      if (this.searching || (text.trim() === '' && option !== 'Recent')) return;

      // Spam prevention
      this.searching = true;

      // Remove leading and trailing spaces
      if (option !== 'Recent') this.searchText = text = text.toString().trim();

      // Getting search Result
      let result = await this.$store.dispatch(this.storeSearchMethod, {
        searchOption: option,
        searchText: text,
        searchLimit: limit,
      });

      // Show "No Results" message when results is empty
      this.showNoResults = !result;

      // Re-enable searching
      this.searching = false;
    },
  },
  mounted() {
    // Select the default searchOption
    this.searchOption = this.searchOptions[0];

    // Put the cursor inside searchInput
    const inputField = document.getElementById('searchInput');
    inputField.focus();
    inputField.select();

    // Perform Recent Search if required
    if (this.lastSearch.searchOption !== '') {
      // Set data from lastsearch
      if (this.lastSearch.searchOption !== 'Recent') this.searchOption = this.lastSearch.searchOption;
      this.searchText = this.lastSearch.searchText;
      this.searchLimit = this.lastSearch.searchLimit;

      // Perform the lastSearch again to remain up to date
      this.search(this.lastSearch.searchOption, this.lastSearch.searchText, this.lastSearch.searchLimit);
    }
  },
};
</script>