import httpClient from '/httpClient';

const search_Email = (email, limit) =>
  httpClient.get('magazines/search/email/' + email, { params: { limit } });

const search_Name = (name, limit) =>
  httpClient.get('magazines/search/name/' + name, { params: { limit } });

const search_Title = (title, limit) =>
  httpClient.get('magazines/search/title/' + title, { params: { limit } });

const search_CreationID = (id) =>
  httpClient.get('magazines/search/id/' + id);


export { search_Email, search_Name, search_Title, search_CreationID };