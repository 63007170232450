import httpClient from '../../../../httpClient';

const search_ID = (id) =>
  httpClient.get('orders/search/id/' + id);

const search_Email = (email, limit) =>
  httpClient.get('orders/search/email/' + email, { params: { limit } });

const search_Name = (name, limit) =>
  httpClient.get('orders/search/name/' + name, { params: { limit } });

const search_PostalCode = (postalcode) =>
  httpClient.get('orders/search/postalcode/' + postalcode);

const search_RecentOrders = (limit) =>
  httpClient.get('orders/recent', { params: { limit } });

export { search_ID, search_Email, search_Name, search_PostalCode, search_RecentOrders };