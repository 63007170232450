<template><p>This should not be shown</p></template>

<script>

export default {
  name: 'Home',
  methods: {
    checkAuth: function() {
      if (this.$store.getters.isAuthenticated) {
        this.$router.push('/orders');
      }
      else {
        this.$router.push('/login');
      }
    },
  },
  beforeMount() {
    this.checkAuth();
  },
};

</script>
