<template>
  <tr v-if="pageInfo.number !== 0">

    <td class="pl-6 py-1">
      <img v-if="pageInfo.thumbnailLink !== ''" id="thumbnail"
           class="h-28 py-1" :src="pageInfo.thumbnailLink"
           @error="ReplaceDefault"
      >
    </td>

    <td class="py-4">
      <div class="text-sm text-gray-900">
        {{ pageInfo.number }}
      </div>
    </td>

    <td class="px-6 py-4 mr-2">
      <div
        class="rounded-full text-center font-semibold uppercase text-sm py-0.5 px-1"
        :class="pageStatusStyle"
      >
        {{ pageStatus }}
      </div>
    </td>

    <td class="pr-6 pl-12 py-4">
      <div class="text-sm text-gray-900">
        <a v-if="pageInfo.status === 3"
           :href="HighRessURL"
           target="_blank"
           class="text-indigo-600 hover:text-indigo-900"
        >
          View
        </a>

        <SVGIcon v-else
                 iconStyle="text-gray-500" d="M6 18L18 6M6 6l12 12"
        />
      </div>
    </td>

    <td class="px-6 py-4">
      <div class="text-sm text-gray-900">
        <button
          class="text-indigo-600 hover:text-indigo-900"
          :class="pageMaker === '-' ? 'cursor-default' : 'cursor-pointer'"
          :disabled="pageMaker === '-'"
          @click="GoToMaker"
        >
          {{ pageMaker }}
        </button>
      </div>
    </td>


    <td class="px-6 py-4">
      <div class="text-sm text-gray-900">
        {{ pageInfo.updated_at }}
      </div>
    </td>

    <td class="px-6 py-4">
      <button class="bg-white text-black test-xs hover:bg-gray-200 active:bg-gray-400
            border border-gray-700 rounded px-1.5 flex"
              @click="ShowPageHistory"
      >

        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 mt-1.5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round"
                stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
          />
          <path stroke-linecap="round" stroke-linejoin="round"
                stroke-width="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
          />
        </svg>

        <span class="font-semibold text-sm leading-5 mx-2 my-2">
          View
        </span>
      </button>
    </td>

  </tr>
</template>

<script>
import { mapGetters } from 'vuex';
import SVGIcon from '../../../components/SVGIcon.vue';

export default {
  name: 'CreationPagesTableRow',
  components: { SVGIcon },
  props: { pageInfo: Object },
  data () {
    return {
      pageMaker: '',
      pageStatus: '',
      pageStatusStyle: '',
    };
  },
  computed: {
    ...mapGetters(['CreationInfo']),
    HighRessURL() {
      let BaseURL = `https://s3.eu-west-1.amazonaws.com/php.jilster.nl/${process.env.VUE_APP_STATUS}_magazinepages/`;
      return `${BaseURL}${this.pageInfo.magazine_id}/png_print/${this.pageInfo.id}.png`;
    },
  },
  methods: {
    SetStatusTextfromID(status_id) {
      switch (status_id) {
        case 1:
          this.pageStatus = 'Open';
          this.pageStatusStyle = 'gray-tag';
          break;
        case 2:
          this.pageStatus = 'Submitted';
          this.pageStatusStyle = 'orange-tag';
          break;
        case 3:
          this.pageStatus = 'Ready';
          this.pageStatusStyle = 'green-tag';
          break;
        default:
          this.pageStatus = '-';
          break;
      }
    },

    getMaker(page_id) {
      var email = '-';
      this.CreationInfo.users.forEach(element => {
        if (element.pivot.magazinepage_id === page_id) {
          if (element.email !== 'info@jilster.nl') email = element.email;
          return email;
        }
      });
      return email;
    },

    GoToMaker() {
      this.$store.dispatch('GoToMaker', this.pageMaker);
    },

    ShowPageHistory() {
      this.$store.dispatch('ShowPageHistory', this.pageInfo);
    },

    // On image error replace with default image
    ReplaceDefault(e) { e.target.src = process.env.VUE_APP_DEFAULT_IMAGE; },
  },
  mounted () {
    this.pageMaker = this.getMaker(this.pageInfo.id);
    this.SetStatusTextfromID(this.pageInfo.status);
  },
};
</script>
