<template>
  <SearchBox searchName="Orders" storeSearchMethod="searchOrders"
             :searchOptions="['Order ID', 'E-mail', 'Name', 'Postal Code']" :lastSearch="LastOrderSearch"
             svgD="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"
  >

    <Table :headers="['Order ID', 'Maker', 'Creation', 'Price', 'Date', 'Status', 'Details']">

      <OrderTableRow v-for="tableRow in OrderTableRows" :key="tableRow.id" :tableRow="tableRow" />

    </Table>

  </SearchBox>
</template>

<script>
import SearchBox from '../../../components/SearchBox.vue';
import Table from '../../../components/Table.vue';
import OrderTableRow from './OrderTableRow.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'OrderSearch',
  components: { SearchBox, Table, OrderTableRow },
  computed: mapGetters(['OrderTableRows', 'LastOrderSearch']),
};
</script>