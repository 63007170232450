<template>
  <tr class="text-sm text-gray-500 wordlimit">
    <td class="px-6 py-4 wordlimit font-bold">
      <img v-if="tableRow.thumbnailLink !== ''" class="h-24" :src="tableRow.thumbnailLink"
           @error="ReplaceDefault"
      >
    </td>

    <td class="px-6 py-4 wordlimit">
      {{ tableRow.title }}
    </td>

    <td class="px-6 py-4 wordlimit">
      {{ tableRow.canvasCount }}
    </td>

    <td class="px-6 py-4 wordlimit">
      {{ tableRow.canvasVersion }}
    </td>

    <td class="px-6 py-4 wordlimit ">
      {{ tableRow.owner }}
    </td>

    <td class="px-6 py-4 wordlimit whitespace-pre-wrap">
      {{ tableRow.lastContentChange }}
    </td>

    <td class="px-6 py-4 whitespace-nowrap">
      <div v-if="tableRow.status === 3"
           class="rounded-full text-center green-tag font-semibold uppercase text-sm w-min py-2 px-2"
      >
        YES
      </div>

      <div v-else
           class="rounded-full text-center gray-tag font-semibold uppercase text-sm w-min py-2 px-2"
      >
        NO
      </div>
    </td>

    <td class="pr-6 py-4 wordlimit">
      <router-link :to="'/creations/' + tableRow.id"
                   class="bg-white text-black test-xs hover:bg-gray-200 active:bg-gray-400
            border border-gray-700 rounded px-1.5 flex"
      >
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 mt-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round"
                stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
          />
          <path stroke-linecap="round" stroke-linejoin="round"
                stroke-width="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
          />
        </svg>
        <span class="font-semibold text-sm leading-4 mx-1.5 my-2">View</span>
      </router-link>
    </td>
  </tr>
</template>

<script>
export default {
  name: 'CreationTableRow',
  props: { tableRow: Object },
  methods: { ReplaceDefault(e) { e.target.src = process.env.VUE_APP_DEFAULT_IMAGE; } },
};
</script>