<template>
  <SearchBox searchName="Makers" storeSearchMethod="searchMakers"
             :searchOptions="['E-mail', 'Name', 'Postal Code']" :lastSearch="LastMakerSearch"
             svgD="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"
  >

    <Table :headers="['Maker', 'Phone Number', 'Address', 'Registration', 'Maker Actions', ' ']">

      <MakerTableRow v-for="tableRow in MakerTableRows" :key="tableRow.id" :tableRow="tableRow" />

    </Table>

  </SearchBox>
</template>

<script>
import SearchBox from '../../components/SearchBox.vue';
import Table from '../../components/Table.vue';
import MakerTableRow from './MakerTableRow.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'MakerSearch',
  components: { SearchBox, Table, MakerTableRow },
  computed: mapGetters(['MakerTableRows', 'LastMakerSearch']),
};
</script>