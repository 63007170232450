<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       class="h-6 w-6"
       :class="iconStyle"
       fill="none"
       viewBox="0 0 24 24"
       stroke="currentColor"
       :transform="transform"
  >
    <path
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
      :d="d"
    />
  </svg>
</template>

<script>

export default {
  name: 'SVGIcon',
  props: {
    d: String,
    iconStyle: String,
    transform: String,
  },
};
</script>
