<template>
  <nav class="shadow-md bg-white fixed w-full top-0 z-20">
    <div class="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
      <div class="relative flex items-center justify-between h-16">
        <div class="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
          <div class="flex-shrink-0 flex items-center">
            <img class="h-10 w-auto"
                 src="../../assets/JIL_Mark(Round).png"
                 alt="Jilster"
            >
          </div>
          <div class="sm:block sm:ml-6">
            <div class="flex space-x-4">

              <router-link to="/orders"
                           active-class="active-router-link"
                           class="text-gray-500 hover:bg-gray-700 hover:text-white
                            px-3 py-2 rounded-md text-sm font-medium"
              >
                Orders
              </router-link>

              <router-link to="/makers"
                           active-class="active-router-link"
                           class="text-gray-500 hover:bg-gray-700 hover:text-white
                            px-3 py-2 rounded-md text-sm font-medium"
              >
                Makers
              </router-link>

              <router-link to="/creations"
                           active-class="active-router-link"
                           class="text-gray-500 hover:bg-gray-700 hover:text-white
                            px-3 py-2 rounded-md text-sm font-medium"
              >
                Creations
              </router-link>

              <router-link to="/editor"
                           active-class="active-router-link"
                           class="text-gray-500 hover:bg-gray-700 hover:text-white
                            px-3 py-2 rounded-md text-sm font-medium"
              >
                Editor
              </router-link>

            </div>
          </div>
        </div>
        <div v-if="isAuthenticated"
             class="absolute inset-y-0 right-0 flex items-center
                    pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0"
        >
          <div class="text-gray-500 px-3 py-2 rounded-md text-sm font-medium">
            {{ getLoggedInAdmin.email }}
          </div>
          <div class="font-bold text-black pb-1 px-2"> | </div>
          <button type="button"
                  @click="logout"
                  class="text-gray-500 hover:bg-gray-700 hover:text-white
                            px-3 py-2 rounded-md text-sm font-medium flex"
          >
            Log Out
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 ml-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
            </svg>
          </button>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'NavBar',
  computed: mapGetters(['isAuthenticated', 'getLoggedInAdmin']),
  methods: {
    async logout () {
      await this.$store.dispatch('logout');
    },
  },
};
</script>

<style scoped>
    .active-router-link {
        --tw-bg-opacity: 1;
        background-color: rgba(17, 24, 39, var(--tw-bg-opacity));
        color: rgba(255, 255, 255, var(--tw-text-opacity));
    }
</style>

