import Vuex from 'vuex';
import Vue from 'vue';
import createPersistedState from 'vuex-persistedstate';
import auth from '../domains/auth/authStore';
import ordersearch from '../domains/orders/OrderSearch/orderSearchStore';
import orderview from '../domains/orders/OrderView/orderviewStore';
import makerSearch from '../domains/makers/makerSearchStore';
import creationSearch from '../domains/creations/CreationSearch/creationSearchStore';
import creationview from '../domains/creations/CreationView/creationViewStore';
import editor from '../domains/editor/editorStore';

// Load Vuex
Vue.use(Vuex);

// Create store
export default new Vuex.Store({
  modules: {
    auth,
    ordersearch,
    orderview,
    makerSearch,
    creationSearch,
    creationview,
    editor,
  },
  plugins: [createPersistedState()],
});


