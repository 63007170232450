import { search_Email, search_Name, search_PostalCode, search_Recent, getBearerToken, updateActive_Maker }
  from './makerSearchEndpoints';

import { ReadableDateTimeFormat } from '@/Helpers/helpers';

const state = {
  makerTableRows: [],
  lastMakerSearch: { searchOption: '', searchText: '', searchLimit: 50 },
  deactivateUserModalEnabledID: -1,
};

const getters = {
  MakerTableRows: (state) => { return state.makerTableRows; },
  LastMakerSearch: (state) => { return state.lastMakerSearch; },
  DeactivateUserModalEnabledID: (state) => { return state.deactivateUserModalEnabledID; },
};

const actions = {
  async searchMakers({ commit }, { searchOption, searchText, searchLimit }) {
    // Variables
    var response = null;

    // Execute API request based on the chosen searchOption
    switch (searchOption) {
      case 'E-mail':
        response = await search_Email(searchText, searchLimit);
        break;
      case 'Name':
        response = await search_Name(searchText, searchLimit);
        break;
      case 'Postal Code':
        response = await search_PostalCode(searchText);
        break;
      case 'Recent':
        response = await search_Recent(searchLimit);
        break;
      default:
        commit('RESET_MAKERTABLE');
        return false;
    }

    // Check if there are results
    if (response.data.message !== 'Found') {
      commit('RESET_MAKERTABLE');
      return false;
    }

    commit('POPULATE_MAKERTABLE', response.data.users);
    commit('SET_LASTMAKERSEARCH', { searchOption: searchOption, searchText: searchText, searchLimit: searchLimit });
    return true;
  },
  // eslint-disable-next-line no-unused-vars
  async loginAsMaker ({ commit }, id) {
    let response = await getBearerToken(id);

    if (response.status === 201) {
      window.open(process.env.VUE_APP_MAKERSPACE_URL + '/login?lettherebeanadminwhomcanhelpmakers=' + response.data.token);
    }
  },

  async deactivateMaker({ commit }, id) {
    let response = await updateActive_Maker(id, false);

    if (response.status === 200) {
      commit('SET_DEACTIVATEUSERMODAL_ID_ENABLED', -1);
      commit('REMOVE_MAKERTABLEROWS', id);
    }
  },
};

const mutations = {
  RESET_MAKERTABLE(state) {
    state.makerTableRows = [];
  },
  POPULATE_MAKERTABLE(state, users) {
    state.makerTableRows = [];

    users.forEach((element) => {
      state.makerTableRows.push({
        id: element.id,
        fullName: element.firstName + element.lastName !== '' ?
          element.firstName + ' ' + element.lastName : element.nickName,
        email: element.email,
        phoneNumber: element.phoneNumber !== null ? element.phoneNumber : '-',
        mobileNumber: element.mobileNumber !== null ? element.mobileNumber : '-',
        registrationDate: ReadableDateTimeFormat(element.created_at, '\n   '),
        address: FormatAddress(element),
      });
    });
  },
  REMOVE_MAKERTABLEROWS(state, id) {
    state.makerTableRows = state.makerTableRows.filter(element => element.id !== id);
  },
  SET_LASTMAKERSEARCH(state, lastSearch) {
    state.lastMakerSearch = lastSearch;
  },
  SET_DEACTIVATEUSERMODAL_ID_ENABLED(state, newValue) {
    state.deactivateUserModalEnabledID = newValue;
  },
};

function FormatAddress(user) {
  var line1;
  var line2;

  if (user.street === null) {
    line1 = '-';
    line2 = '';
  }
  else {
    line1 = `${user.street || ''} ${user.houseNumber || ''}${user.houseNumberSuffix || ''}, 
        ${user.postalCode || ''}`;
    line2 = `${user.city || ''} (${user.country !== null ? user.country.code : '-'})`;
  }

  return {
    line1: line1,
    line2: line2,
  };
}

export default {
  state,
  getters,
  actions,
  mutations,
};
