<template>
  <Modal>
    <!-- Header -->
    <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
      <div class="sm:flex sm:items-start">
        <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12
                rounded-full gray-tag sm:mx-0 sm:h-10 sm:w-10"
        >
          <!-- Heroicon name: cloud-upload -->
          <SVGIcon d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
        </div>
        <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
          <!-- Title -->
          <h3 class="text-lg leading-6 font-medium text-gray-900 mt-1.5" id="modal-title">
            Upload new Image into: {{ ActiveFolder.title }}
          </h3>

          <!-- Input Field -->
          <div class="mt-3">
            <label for="ImageInput" class="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
              <span v-if="selectedFile === null"> Browse </span>
              <span v-else> {{ selectedFile.name }} </span>
            </label>
            <input
              style="display:none"
              type="file"
              name="ImageInput"
              id="ImageInput"
              accept=".png,.jpg,.bmp,.gif"
              @change="onFileSelected"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- Footer -->
    <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse flex justify-between">
      <div class="flex">
        <button type="button" @click="UploadSelectedFile"
                class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-500 hover:bg-blue-700 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-3 sm:w-auto sm:text-sm"
        >
          <span class="text-left">Upload</span>
          <SVGIcon v-if="!dataLoaded"
                   iconStyle="animate-reverse-spin ml-2 h-5"
                   d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
          />
        </button>
        <button type="button" @click="HideModal"
                class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
        >
          Cancel
        </button>
      </div>

      <span v-if="errorText !== ''" class="px-2 py-2 whitespace-nowrap text-red-400">{{ errorText }}</span>
    </div>
  </Modal>
</template>

<script>
import { mapGetters } from 'vuex';
import SVGIcon from '../../../components/SVGIcon.vue';
import Modal from '../../../components/Modal.vue';

export default {
  name: 'UploadImageModal',
  components: {
    SVGIcon,
    Modal,
  },
  data () {
    return {
      selectedFile: null,
      dataLoaded: true,
      errorText: '',
    };
  },
  computed: mapGetters(['UploadImageModalEnabled', 'ActiveFolder']),
  methods: {
    async onFileSelected(event) {
      const maxAllowedSize = 25 * 1024 * 1024; // 25 MB
      const file = event.target.files[0];
      const input = document.getElementById('ImageInput');

      // Reset variables
      this.errorText = '';
      this.selectedFile = null;

      if (file.size > maxAllowedSize) {
        this.errorText = 'Max file size is 25MB';
        input.value = null;
      }
      else {
        this.selectedFile = file;
      }
    },

    async UploadSelectedFile() {
      if (this.selectedFile === null) {
        this.errorText = 'Please select a file first';
        return;
      }

      this.dataLoaded = false;

      let result = await this.$store.dispatch('UploadImage', this.selectedFile);

      if (result !== 'succes') this.errorText = result;

      this.dataLoaded = true;
      this.selectedFile = null;
    },

    HideModal() {
      this.$store.commit('SET_UPLOADIMAGEMODAL_ENABLED', false);
    },
  },
};
</script>
